import { values } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const RegionModel = types.model('region', {
  'id': types.number,
  'name': types.string,
})

export const RegionsModel = types.model('PaRegionsModelyout', {
  regions: types.optional(types.array(RegionModel), []),
})

export const regionActions = (self: DataStore) => ({
  getRegions: flow(function*() {
    self.regions = yield self.request('get', 'common/regions')
  }),
})

export const regionViews = (self: DataStore) => ({
  get regionsList() {
    return values(self.regions) as RegionType[]
  },
})

export type RegionType = typeof RegionModel.Type
export type RegionSnapshot = typeof RegionModel.SnapshotType
