const config: Config = isProductionEnvironment()
  ? (require('./env.prod') as typeof import('./env.prod')).config
  : (require('./env.dev') as typeof import('./env.dev')).config

function isProductionEnvironment() {
  // if (navigator.product === 'ReactNative') {
  //   const { Config } = require('../../mobile/node_modules/react-native-config')
  //   return Config.NODE_ENV === 'production'
  // }

  return process.env.NODE_ENV === 'production'
}

export default config

export type Config = {
  API_URL: string
  USE_REAL_API: boolean
  SHOW_MESSAGES_TAB: boolean
}
