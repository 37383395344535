import { ApiConfig } from 'ogram-react/api/api-config'
import config from '../../config/env'

/**
 * The default configuration for the app.
 */
export const DEFAULT_API_CONFIG: ApiConfig = {
  url: config.API_URL,
  timeout: 100000,
  lang: 'en-us',
  refreshURL: 'sp/auth/refresh',
}
