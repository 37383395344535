import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import JobFullfilled from '../../../../components/job-fullfilled/job-fullfilled'
import LoadingSpinner from '../../../../components/loading-spinner/loading-spinner'
import { useQuery, useStore } from '../../../../models/utils/mst-hooks'
import { FormatedJobErrror, formatJobDetailsError } from '../../../../utils/job-utils'
import Header from '../header/header'
import './job-requirements.scss'
import { useState } from 'react'
import { useEffect } from 'react'
import { routes } from '../../../../routes'

interface JobRequirementsProps {
  onComplete: () => void
}

const JobRequirements = observer(({ onComplete }: JobRequirementsProps) => {
  const { getJob, job, saveRequirementAnswer, getAnswers } = useStore().data
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const [answers, setAnswers] = useState<{ [key: string]: string }>({})
  const [showRequiredMessage, setShowRequiredMessage] = useState(false)
  const [jobDetailsError, setJobDetailsError] = React.useState<FormatedJobErrror>()
  const [showJobDetailsError, setShowJobDetailsError] = React.useState(false)
  const jobQuery = useQuery(() => getJob(Number(id)), {
    filterError: [ApiErrorTypes.ClientError],
    onError: error => {
      if (error.data.errors) {
        setJobDetailsError(formatJobDetailsError(error.data.errors))
      }
      setShowJobDetailsError(true)
    },
  })
  const requirements = job?.work_requirements

  useEffect(() => {
    const savedAnswers = getAnswers()
    const formatedAnswers: { [key: string]: string } = {}
    savedAnswers.forEach(answer => (formatedAnswers[answer.id] = answer.answer))
    setAnswers(formatedAnswers)
  }, [getAnswers])

  const handleCheckboxChange = (questionId: number, answer: string) => {
    setAnswers(prevAnswers => ({ ...prevAnswers, [questionId]: answer }))
  }

  const validateAnswers = () => {
    const countOfAnsweredQuestions = Object.keys(answers).length
    return countOfAnsweredQuestions === requirements?.length
  }

  const submit = () => {
    if (validateAnswers()) {
      setShowRequiredMessage(false)
      let isAcceptedAllRequirements = true
      for (const answerId in answers) {
        if (answers.hasOwnProperty(answerId)) {
          const reqName = requirements?.find(req => req.id === Number(answerId))?.name
          saveRequirementAnswer(answerId, answers[answerId], String(reqName))
          if (answers[answerId] === 'no') {
            isAcceptedAllRequirements = false
          }
        }
      }
      if (isAcceptedAllRequirements) {
        onComplete()
      } else {
        history.push(routes.acceptJobRequirementsNotAccepted)
      }
    } else {
      setShowRequiredMessage(true)
    }
  }

  return (
    <>
      {!jobQuery.isLoading ? (
        showJobDetailsError ? (
          <JobFullfilled jobDetailsError={jobDetailsError} />
        ) : (
          <div className="JobRequirements">
            <Header title="Job Requirements" />
            <h2 className="JobRequirements__title">Please acknowledge that you meet the following requirements:</h2>
            <div className="JobRequirements__body">
              <div className="JobRequirements__requirementsList">
                <div className="JobRequirements__requirementRow">
                  <div className="JobRequirements__yesAndNo">
                    <p>No</p>
                    <p>Yes</p>
                  </div>
                </div>
                {requirements?.map(requirement => (
                  <div key={requirement.id} className="JobRequirements__requirementRow">
                    <p className="JobRequirements__requirementName">{requirement.name}</p>
                    <div className="JobRequirements__checkBoxes">
                      <label className="JobRequirements__customCheckbox">
                        <input
                          onChange={() => handleCheckboxChange(requirement.id, 'no')}
                          type="checkbox"
                          checked={answers[requirement.id] === 'no'}
                        />
                        <span className="checkmark"></span>
                      </label>
                      <label className="JobRequirements__customCheckbox">
                        <input
                          onChange={() => handleCheckboxChange(requirement.id, 'yes')}
                          type="checkbox"
                          checked={answers[requirement.id] === 'yes'}
                        />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                  </div>
                ))}
                {showRequiredMessage ? (
                  <p className="JobRequirements__errorMessage">Please Answer all questions</p>
                ) : null}
              </div>
              <button className="JobRequirements__nextButton" onClick={submit} type="submit">
                Confirm
              </button>
            </div>
          </div>
        )
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
})

export default JobRequirements
