import * as React from 'react'
import { Field, FieldProps } from '../field/field'
import { FormField } from '../form'
import './input.scss'

type InputProps = {
  type?: 'text' | 'phone' | 'email' | 'password'
  required?: boolean
  field?: FormField<string>
  multiline?: boolean
  value?: string
  autocomplete?: string[]
  onChange?: (newVal: string) => void
}

export function Input(props: InputProps) {
  const handleChange = (value: string) => {
    props.field?.setData(value)
    props.onChange?.(value)
  }
  const [autocompleteShown, setAutocompleteShown] = React.useState(false)
  const ref = React.createRef<HTMLInputElement>()
  const Tag = (props.multiline ? 'textarea' : 'input') as 'input'
  return (
    <div className="Input">
      <Tag
        ref={ref}
        className="Input__input"
        type={props.type}
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        value={props.value ?? props.field?.value ?? ''}
        onChange={e => handleChange(e.target.value)}
        required={props.required}
        onFocus={() => setAutocompleteShown(true)}
        onBlur={() => setAutocompleteShown(false)}
      />
      {autocompleteShown && props.autocomplete && props.autocomplete.length > 0 && (
        <div className="Input__autocomplete">
          {props.autocomplete.map((it, i) => (
            <div
              className="Input__autocompleteItem"
              key={i}
              onMouseDown={() => {
                handleChange(it)
              }}
            >
              {it}
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export function InputField(props: InputProps & FieldProps) {
  return (
    <Field
      label={props.label}
      description={props.description}
      required={props.required}
      errors={props.errors ?? props.field?.errors}
    >
      <Input {...props} />
    </Field>
  )
}
