import { observer } from 'mobx-react'
import * as React from 'react'
import { useMutation, useStore } from '../../../models/utils/mst-hooks'
import './header.scss'
import { routes } from '../../../routes'
import { useHistory } from 'react-router-dom'

export const Header = observer(() => {
  const store = useStore()
  const history = useHistory()
  const [result, logout] = useMutation(() => store.data.logout({ device_id: '11' }), {
    onSuccess: () => {
      history.push(routes.login)
      window.location.reload()
    },
  })
  return (
    <div className="Header">
      Hello {store.data.profile.basic?.name}
      <div>
        <button onClick={logout} disabled={result.isLoading}>
          Logout
        </button>
      </div>
    </div>
  )
})
