import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import './policy.scss'
import { policyType } from '../../pages/accept-job/accept-job-policy/accept-job-policy'
import Stepper from '../stepper/stepper'
import Header from '../../pages/accept-job/components/header/header'

interface PolicyInterface {
  onComplete: () => void
  policy: policyType[]
}

const queryString = require('query-string')

const Policy = ({ onComplete, policy }: PolicyInterface) => {
  const location = useLocation()
  const history = useHistory()
  const policyPageIndex = Number(queryString.parse(location.search).policyPageIndex ?? 0)
  const nextpolicyPageIndex: number = policyPageIndex + 1
  const isNextPolicyExist = nextpolicyPageIndex < policy.length

  const generateNextPageLink = () => {
    if (isNextPolicyExist) {
      history.push({ pathname: location.pathname, search: `policyPageIndex=${nextpolicyPageIndex}` })
    } else {
      onComplete()
    }
  }

  return (
    <div className="Policy">
      <Header title={policy[policyPageIndex].headerTitle} />
      <div className="Policy__image">
        <img src={policy[policyPageIndex].image} />
      </div>
      <h3 className="Policy__title">{policy[policyPageIndex].title}</h3>
      <p className="Policy__description" dangerouslySetInnerHTML={{ __html: policy[policyPageIndex].description }} />
      <div className="Policy__steps">
        <Stepper activeStepIndex={policyPageIndex} stepsCount={2} />
      </div>
      <button onClick={generateNextPageLink} className="Policy__nextButton">
        I Agree
      </button>
    </div>
  )
}

export default Policy
