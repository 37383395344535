import * as React from 'react'
import PayoutSelectBank from '../../../components/payout-select-bank/payout-select-bank'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { routes } from '../../../routes'

const AcceptJobPayoutSelectBank = () => {
  const { id } = useParams<{ id: string }>()
  const nextRoute = generatePath(routes.acceptJobPayoutIBAN, { id })

  const history = useHistory()
  const onComplete = () => history.push(nextRoute)

  return <PayoutSelectBank onComplete={onComplete} />
}

export default AcceptJobPayoutSelectBank
