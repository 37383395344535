import { cast, types } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const step = types.model('step', {
  'description': types.string,
  'time': types.number,
  'status': types.string,
  'propKey': types.string,
})

export const progressStepsActions = (self: DataStore) => {
  return {
    setSteps: (progressSteps: StepType[]) => {
      self.acceptJobProgressSteps = cast(progressSteps)
    },
  }
}
export const progressStepsViews = (self: DataStore) => ({
  getSteps() {
    return Array.from(self.acceptJobProgressSteps.values())
  },
})

export type StepType = typeof step.Type
