import { types, flow, Instance } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const UniformModel = types.model('uniform', {
  id: types.number,
  name: types.string,
  description: types.string,
  image_url: types.string,
})

export const uniformActions = (self: DataStore) => ({
  getUniforms: flow(function*() {
    const uniformMap = (yield self.request('get', 'common/uniforms')) as UniformSnapshot[]
    uniformMap.forEach(uniform => {
      self.uniforms.set(String(uniform.id), uniform)
    })
  }),
})

export const uniformViews = (self: DataStore) => ({
  get uniformList() {
    return Array.from(self.uniforms.values())
  },

  getUniformByName(name: string) {
    return Array.from(self.uniforms.values()).find(uniform => uniform.name === name)
  },
})

export type UniformType = Instance<typeof UniformModel>
export type UniformSnapshot = Instance<typeof UniformModel>
