import * as React from 'react'
import { Modal } from '../modal/modal'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import './upload-confirmation-modal.scss'

interface UploadConfirmationModalProps {
  modalIsOpen: boolean
  confirm: () => void
  isLoading: boolean
}

const UploadConfirmationModal = ({ modalIsOpen, confirm, isLoading }: UploadConfirmationModalProps) => {
  return (
    <Modal modalIsOpen={modalIsOpen} className="UploadConfirmationModal">
      {isLoading ? (
        <>
          <LoadingSpinner />
          <p className="">Uploading Document...</p>
        </>
      ) : (
        <>
          <p>Uploading Finished!</p>
          <button className="UploadConfirmationModal__confirmButton" onClick={confirm}>
            Ok
          </button>
        </>
      )}
    </Modal>
  )
}

export default UploadConfirmationModal
