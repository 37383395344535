import { types, flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const LanguageModel = types.model('region', {
  id: types.number,
  name: types.string,
})

export const languageActions = (self: DataStore) => ({
  getLanguages: flow(function*() {
    self.languages = yield self.request('get', 'common/languages')
  }),
})

export type LanguageType = typeof LanguageModel.Type
export type LanguageSnapshot = typeof LanguageModel.SnapshotType
