// @ts-nocheck
interface Window {
  onYouTubeIframeAPIReady: () => void
}

type eventType = {
  data: Record<string, unknown>
}

declare const window: Window

export const hideYoutubeRelatedVideos = (containerClassName: string) => {
  const onYouTubeIframeAPIReadyCallbacks: (() => void)[] = []
  for (const playerWrap of document.querySelectorAll(`.${containerClassName}`)) {
    const playerFrame = playerWrap.querySelector('iframe')
    const tag = document.createElement('script')
    tag.src = 'https://www.youtube.com/iframe_api'
    const firstScriptTag = document.getElementsByTagName('script')[0]
    firstScriptTag.parentNode?.insertBefore(tag, firstScriptTag)
    const onPlayerStateChange = function(event: eventType) {
      if (event.data === YT.PlayerState.ENDED) {
        playerWrap.classList.add('ended')
      } else if (event.data === YT.PlayerState.PAUSED) {
        playerWrap.classList.add('paused')
      } else if (event.data === YT.PlayerState.PLAYING) {
        playerWrap.classList.remove('ended')
        playerWrap.classList.remove('paused')
      }
    }
    let player: { getPlayerState: () => never; seekTo: (arg0: number) => void; playVideo: () => void }
    onYouTubeIframeAPIReadyCallbacks.push(() => {
      player = new YT.Player(playerFrame, { events: { 'onStateChange': onPlayerStateChange } })
    })
    playerWrap.addEventListener('click', () => {
      const playerState = player.getPlayerState()
      if (playerState === YT.PlayerState.ENDED) {
        player.seekTo(0)
      } else if (playerState === YT.PlayerState.PAUSED) {
        player.playVideo()
      }
    })
  }
  window.onYouTubeIframeAPIReady = function() {
    for (const callback of onYouTubeIframeAPIReadyCallbacks) {
      callback()
    }
  }
}
