import { observer } from 'mobx-react'
import * as React from 'react'
import { ProfileForm } from '../../pages/profile/profile-form/profile-form'
import { routes } from '../../routes'
import './app-layout.scss'
import { Footer } from './footer/footer'
import { Header } from './header/header'
import { SideBar } from './side-bar/side-bar'
import { useStore } from '../../models/utils/mst-hooks'
import { Login } from '../../pages/auth/login/login'
import AcceptJobProgress from '../../pages/accept-job/accept-job-progress/accept-job-progress'
import AcceptJobQuiz from '../../pages/accept-job/accept-job-quiz/accept-job-quiz'
import { Switch, Route } from 'react-router-dom'
import AcceptJobShifts from '../../pages/accept-job/accept-job-shifts/accept-job-shifts'
import AcceptJobConfirmation from '../../pages/accept-job/accept-job-confirmation/accept-job-confirmation'
import AcceptJobAlreadyFullfilled from '../../pages/accept-job/accept-job-already-fullfilled/accept-job-already-fullfilled'
import AcceptJobPayoutChoosePaymentMethod from '../../pages/accept-job/accept-job-payout-choose-payment-methods/accept-job-payout-choose-payment-methods'
import AcceptJobPayoutSelectBank from '../../pages/accept-job/accept-job-payout-select-bank/accept-job-payout-select-bank'
import AcceptJobPayoutIban from '../../pages/accept-job/accept-job-payout-iban/accept-job-payout-iban'
import AcceptJobPayoutName from '../../pages/accept-job/accept-job-payout-name/accept-job-payout-name'
import AcceptJobPayoutTerms from '../../pages/accept-job/accept-job-payout-terms/accept-job-payout-terms'
import AcceptJobPayoutPaymaxOptions from '../../pages/accept-job/accept-job-payout-paymax-options/accept-job-payout-paymax-options'
import AcceptJobPayoutPaymaxTerms from '../../pages/accept-job/accept-job-payout-paymax-terms/accept-job-payout-paymax-terms'
import AcceptJobPayoutPaymaxTiming from '../../pages/accept-job/accept-job-payout-paymax-timing/accept-job-payout-paymax-timing'
import AcceptJobPayoutPaymaxLocation from '../../pages/accept-job/accept-job-payout-paymax-location/accept-job-payout-paymax-location'
import AcceptJobRequirementsNotAccepted from '../../pages/accept-job/accept-job-requirements-not-accepted/accept-job-requirements-not-accepted'
import AcceptJobRequiredDocuments from '../../pages/accept-job/accept-job-required-documents/accept-job-required-documents'
import { AcceptJobRequirementsWithPolicies } from '../../pages/accept-job/accept-job-requirements-with-policies/accept-job-requirements-with-policies'

export const AppLayout = observer(() => {
  const store = useStore()
  return (
    <div className="AppLayout">
      <div id="modals"></div>
      {store.data.logged ? (
        <>
          <Header />
          <div className="AppLayout__center">
            <SideBar />
            <main className="AppLayout__main">
              <Switch>
                <Route path={routes.editProfile}>
                  <ProfileForm />
                </Route>
              </Switch>
            </main>
          </div>
          <Footer />
        </>
      ) : (
        <Switch>
          <Route exact path={routes.acceptJobProgress}>
            <AcceptJobProgress />
          </Route>
          <Route exact path={routes.acceptJobDocuments}>
            <AcceptJobRequiredDocuments />
          </Route>
          <Route exact path={routes.acceptJobQuiz}>
            <AcceptJobQuiz />
          </Route>
          <Route exact path={routes.acceptJobRequirementsAndPolicies}>
            <AcceptJobRequirementsWithPolicies
              nextRoute={{
                pathname: routes.acceptJobProgress,
                search: `?nextRoute=${routes.acceptJobShifts}&dismissRefreshProfileData=1`,
              }}
            />
          </Route>
          <Route exact path={routes.acceptJobShifts}>
            <AcceptJobShifts />
          </Route>
          <Route exact path={routes.acceptJobConfirmation}>
            <AcceptJobConfirmation />
          </Route>
          <Route exact path={routes.acceptJobFullfilled}>
            <AcceptJobAlreadyFullfilled />
          </Route>
          <Route exact path={routes.acceptJobPayoutChoosePayment}>
            <AcceptJobPayoutChoosePaymentMethod />
          </Route>
          <Route exact path={routes.acceptJobPayoutChooseBank}>
            <AcceptJobPayoutSelectBank />
          </Route>
          <Route exact path={routes.acceptJobPayoutIBAN}>
            <AcceptJobPayoutIban />
          </Route>
          <Route exact path={routes.acceptJobPayoutName}>
            <AcceptJobPayoutName />
          </Route>
          <Route exact path={routes.acceptJobPayoutTerms}>
            <AcceptJobPayoutTerms />
          </Route>
          <Route exact path={routes.acceptJobPayoutChoosePayMaxCard}>
            <AcceptJobPayoutPaymaxOptions />
          </Route>
          <Route exact path={routes.acceptJobPayoutPayMaxTerms}>
            <AcceptJobPayoutPaymaxTerms />
          </Route>
          <Route exact path={routes.acceptJobPayoutPayMaxTiming}>
            <AcceptJobPayoutPaymaxTiming />
          </Route>
          <Route exact path={routes.acceptJobPayoutPayMaxLocation}>
            <AcceptJobPayoutPaymaxLocation />
          </Route>
          <Route exact path={routes.acceptJobRequirementsNotAccepted}>
            <AcceptJobRequirementsNotAccepted />
          </Route>
          <Route exact path={routes.login}>
            {/* //TODO: add onLogin prop for Login to be called when user logged in  */}
            <Login />
          </Route>
        </Switch>
      )}
    </div>
  )
})
