import * as React from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import PayoutTerms from '../../../components/payout-terms/payout-terms'
import { routes } from '../../../routes'
import { useSameStepsComplete } from '../../../utils/use-same-steps-complete'

const AcceptJobPayoutTerms = () => {
  const { id } = useParams<{ id: string }>()
  const nextRoute = generatePath(routes.acceptJobProgress, { id })
  const history = useHistory()
  const { nextRouteObject, checkStepAsDone } = useSameStepsComplete(
    {
      pathname: nextRoute,
      search: `?nextRoute=${routes.acceptJobRequirementsAndPolicies}&dismissRefreshProfileData=1`,
    },
    'is_payout_method_complete',
    'is_policies_complete',
  )

  const onComplete = () => {
    checkStepAsDone()
    history.push(nextRouteObject())
  }

  return <PayoutTerms onComplete={onComplete} />
}

export default AcceptJobPayoutTerms
