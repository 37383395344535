import * as React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { useStore } from '../../../models/utils/mst-hooks'
import { useSameStepsComplete } from '../../../utils/use-same-steps-complete'
import { AcceptJobAttendancePolicy } from '../accept-job-attendance-policy/accept-job-attendance-policy'
import AcceptJobRequirements from '../accept-job-requirements/accept-job-requirements'
import AcceptJobPolicy from '../accept-job-policy/accept-job-policy'

export const AcceptJobRequirementsWithPolicies = observer(
  (props: { nextRoute: { pathname: string; search: string } }) => {
    const { job } = useStore().data
    const isAnyRequirements = Boolean(job?.work_requirements.length)

    const [requirementsAccepted, setRequirementsAccepted] = React.useState(!isAnyRequirements)
    const [jobPolicyAccepted, setJobPolicyAccepted] = React.useState(false)

    const { nextRoute } = props
    const history = useHistory()
    const { nextRouteObject, checkStepAsDone } = useSameStepsComplete(
      nextRoute,
      'is_policies_complete',
      'is_shifts_complete',
    )

    const handleRequirementsComplete = () => {
      setRequirementsAccepted(true)
    }

    const handleJobPolicyCompelete = () => {
      setJobPolicyAccepted(true)
    }

    const handleSubmit = () => {
      checkStepAsDone()
      history.push(nextRouteObject())
    }

    if (requirementsAccepted && jobPolicyAccepted) {
      return <AcceptJobAttendancePolicy handleSubmit={handleSubmit} />
    }

    if (requirementsAccepted) {
      return <AcceptJobPolicy onComplete={handleJobPolicyCompelete} />
    }

    return <AcceptJobRequirements onComplete={handleRequirementsComplete} />
  },
)
