import * as React from 'react'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import JobFullfilled from '../../../components/job-fullfilled/job-fullfilled'
import { useQuery, useStore } from '../../../models/utils/mst-hooks'
import { FormatedJobErrror, formatJobDetailsError } from '../../../utils/job-utils'

const AcceptJobAlreadyFullfilled = observer(() => {
  const { getJob } = useStore().data
  const { id } = useParams<{ id: string }>()
  const [jobDetailsError, setJobDetailsError] = useState<FormatedJobErrror>()
  const jobQuery = useQuery(() => getJob(Number(id)), {
    filterError: [ApiErrorTypes.ClientError],
    onError: err => {
      if (err.data.errors) {
        setJobDetailsError(formatJobDetailsError(err.data.errors))
      }
    },
  })

  return <>{!jobQuery.isLoading ? <JobFullfilled jobDetailsError={jobDetailsError}></JobFullfilled> : null}</>
})

export default AcceptJobAlreadyFullfilled
