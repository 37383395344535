import * as React from 'react'

import Header from '../components/header/header'
import { PolicyItem } from './components/policy-item/policy-item'
import './accept-job-attendance-policy.scss'

type Props = {
  handleSubmit: () => void
}

export const AcceptJobAttendancePolicy = ({ handleSubmit }: Props) => {
  const [onlineCheckinChecked, setOnlineCheckinChecked] = React.useState(false)
  const [internetUseChecked, setInternetUseChecked] = React.useState(false)
  const [punctualityChecked, setPunctualityChecked] = React.useState(false)
  const [onlineCheckoutChecked, setOnlineCheckoutChecked] = React.useState(false)

  const submitDisabled = !onlineCheckinChecked || !internetUseChecked || !punctualityChecked || !onlineCheckoutChecked

  return (
    <div className="AttendancePolicy">
      <Header title="Attendance Policies" />
      <h2 className="AttendancePolicy__title">Online Attendance Enabled!</h2>
      <PolicyItem
        label="I will log my hours using the app instead of paper timesheets"
        selected={onlineCheckinChecked}
        onChange={() => {
          setOnlineCheckinChecked(prev => !prev)
        }}
      />
      <PolicyItem
        label="I will ensure that I have mobile data or access to a public WiFi"
        selected={internetUseChecked}
        onChange={() => {
          setInternetUseChecked(prev => !prev)
        }}
      />
      <PolicyItem
        label="I will `Start` shifts every day at the scheduled start time"
        selected={punctualityChecked}
        onChange={() => {
          setPunctualityChecked(prev => !prev)
        }}
      />
      <PolicyItem
        label="I will ‘Complete' shifts once I'm done for the day"
        selected={onlineCheckoutChecked}
        onChange={() => {
          setOnlineCheckoutChecked(prev => !prev)
        }}
      />
      <div className="AttendancePolicy__spacer" />
      <button onClick={handleSubmit} disabled={submitDisabled} className="AttendancePolicy__button">
        <span>Confirm</span>
      </button>
    </div>
  )
}
