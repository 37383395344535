import * as React from 'react'
import './job-fullfilled.scss'
import locationIcon from '../../pages/accept-job/assets/location.svg'
import clock from '../../pages/accept-job/assets//clock-blue.svg'
import calender from '../../pages/accept-job/assets//calender.svg'
import { FormatedJobErrror } from '../../utils/job-utils'
import { closeWebView, acceptJobEvents } from '../../utils/webview'

interface JobFullfilledProps {
  jobDetailsError?: FormatedJobErrror | null
}

const JobFullfilled = ({ jobDetailsError }: JobFullfilledProps) => {
  return (
    <>
      <div className="JobFullfilled">
        <h2 className="JobFullfilled__title">Oops! The job has already been fulfilled.</h2>
        {jobDetailsError ? (
          <div className="JobFullfilled__jobDetails">
            <div className="JobFullfilled__jobTitle">
              <h4>{jobDetailsError.job_work_category_name}</h4>
              <span>Fulfilled</span>
            </div>
            <p>
              <img src={locationIcon} /> {jobDetailsError?.job_region}
            </p>
            <div className="JobFullfilled__jobDetailsData">
              <div>
                <img src={clock} />
                <p>{jobDetailsError?.job_total_job_hours} hours</p>
              </div>
              <div>
                <img src={calender} />
                <p>
                  {jobDetailsError?.job_start_date} - {jobDetailsError?.job_end_date}
                </p>
              </div>
              <div>
                <img src={clock} />
                <p>AED {jobDetailsError?.job_expected_hour_rate} / hr</p>
              </div>
            </div>
          </div>
        ) : null}
        <h3 className="JobFullfilled_tipsTitle">Here are a few tips for you:</h3>
        <ul className="JobFullfilled__list">
          <li>
            <span>1</span>
            <p>Turn on your phone notifications</p>
          </li>
          <li>
            <span>2</span>
            <p>Regularly check the explore tab</p>
          </li>
        </ul>
        <p className="JobFullfilled_hint">
          We continuously work to increase the number of jobs on Ogram, so that you can find a job with us.
        </p>
        <button
          className="JobFullfilled__closeButton"
          onClick={() => closeWebView(acceptJobEvents.job_application_failed)}
        >
          Explore Jobs
        </button>
      </div>
    </>
  )
}

export default JobFullfilled
