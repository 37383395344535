import { types } from 'mobx-state-tree'
import { DataStoreModel } from '../../../../common/models/data-store/data-store'
import * as React from 'react'

export const RootStoreModel = types.model({
  data: DataStoreModel,
})
export type RootStore = typeof RootStoreModel.Type
export type RootStoreSnapshot = typeof RootStoreModel.SnapshotType

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
export const context = React.createContext<unknown>(null!)
