import { values } from 'mobx'
import { types, flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'
import { cast } from 'mobx-state-tree'

export const LariExchangeLocationModel = types.model('', {
  id: types.number,
  emirate: types.string,
  name: types.string,
  address: types.string,
})

export const lariLocationsActions = (self: DataStore) => ({
  getLariExchangeLocations: flow(function*() {
    const lariExchangeBranches: LariExchangeLocationsSnapshot[] = yield self.request('get', 'sp/lari-exchange/branches')
    self.lariExchangeBranches = cast(lariExchangeBranches)
  }),
})

export const lariLocationsViews = (self: DataStore) => ({
  get exchangeLocationsList() {
    return values(self.lariExchangeBranches) as LariExchangeLocationsType[]
  },
})

export type LariExchangeLocationsType = typeof LariExchangeLocationModel.Type
export type LariExchangeLocationsSnapshot = typeof LariExchangeLocationModel.SnapshotType
