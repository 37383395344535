import * as React from 'react'
import './accept-job-progress.scss'
import close from '../assets/close.svg'
import OverviewSteps, { OverviewStatus } from '../components/overview-steps/overview-steps'
import { routes } from '../../../routes'
import { useParams, Link, generatePath, useLocation, useHistory } from 'react-router-dom'
import back from '../assets/back.svg'
import { useMutation, useQuery, useStore } from '../../../models/utils/mst-hooks'
import { observer } from 'mobx-react'
import { useEffect } from 'react'
import { StepsCountNaming } from '../../../utils/quiz-util'
import { closeWebView, acceptJobEvents } from '../../../utils/webview'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { FormatedJobErrror, formatJobDetailsError } from '../../../utils/job-utils'
import JobFullfilled from '../../../components/job-fullfilled/job-fullfilled'

const queryString = require('query-string')

const AcceptJobProgress = observer(() => {
  const { profile, getProfile, getSteps, getJob, job } = useStore().data
  const [jobDetailsError, setJobDetailsError] = React.useState<FormatedJobErrror>()
  const [showJobDetailsError, setShowJobDetailsError] = React.useState(false)
  const location = useLocation()
  const history = useHistory()
  const dismissRefreshProfileData = queryString.parse(location.search).dismissRefreshProfileData
  let { nextRoute } = queryString.parse(location.search)
  const [query, fetchProfile] = useMutation(getProfile)
  const { id } = useParams<{ id: string }>()
  const jobQuery = useQuery(() => getJob(Number(id)), {
    filterError: [ApiErrorTypes.ClientError],
    onError: error => {
      if (error.data.errors) {
        setJobDetailsError(formatJobDetailsError(error.data.errors))
      }
      setShowJobDetailsError(true)
    },
  })
  const stepsCount = getSteps().filter(step => step.status !== OverviewStatus.done).length
  useEffect(() => {
    if (!Number(dismissRefreshProfileData) || !profile.basic) {
      fetchProfile({})
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isBackButton = Boolean(nextRoute)
  const goBack = () => history.goBack()

  if (!nextRoute) {
    if (job?.missing_documents.length) {
      nextRoute = routes.acceptJobDocuments
    } else if (!profile.basic?.is_training_complete) {
      nextRoute = routes.acceptJobQuiz
    } else if (!profile.basic.is_payout_method_complete) {
      nextRoute = routes.acceptJobPayoutChoosePayment
    } else {
      nextRoute = routes.acceptJobRequirementsAndPolicies
    }
  }

  return (
    <>
      {jobQuery.isLoading || query.isLoading ? (
        <LoadingSpinner />
      ) : showJobDetailsError ? (
        <JobFullfilled jobDetailsError={jobDetailsError} />
      ) : (
        <div className="Overview">
          <div className="Overview__close">
            {isBackButton ? (
              <img className="JobOverview__backButton" onClick={goBack} src={back} alt="back to previous screen" />
            ) : (
              <img
                onClick={() => closeWebView(acceptJobEvents.job_application_cancelled)}
                src={close}
                alt="close or cancel accept job"
              />
            )}
          </div>

          <h3 className="Overview__desc">
            You're almost there. {StepsCountNaming[stepsCount]} more step(s) before you can take the job.
          </h3>
          <OverviewSteps />
          <Link to={{ pathname: generatePath(nextRoute, { id }) }} className="Overview__continueButton">
            Continue
          </Link>
        </div>
      )}
    </>
  )
})

export default AcceptJobProgress
