import * as React from 'react'
import { QuestionDataType } from '../../../../common/models/quiz'
import check from '../../pages/accept-job/assets/check.svg'
import wrong from '../../pages/accept-job/assets/wrong.png'
import './choice-question.scss'
import { useHistory } from 'react-router-dom'
import { useStore } from '../../models/utils/mst-hooks'
import Stepper from '../stepper/stepper'
import { useEffect, useState } from 'react'

interface ChoiceQuestionProps {
  question: QuestionDataType
  onComplete: () => void
}

const ChoiceQuestion = (props: ChoiceQuestionProps) => {
  const { question, onComplete } = props
  const { data } = useStore()
  const history = useHistory()
  const [selectedAnswer, setSelectedAnswer] = useState<number>()
  const [isCorrectAnswerSelected, setIsCorrectAnswerSelected] = useState<boolean>(false)
  const [isSubmitDisabled, setIsSubmitDisabled] = useState<boolean>(true)
  const [showHint, setShowHint] = useState<boolean>(false)
  const currentQuestionIndex = data.quiz.questions.indexOf(question)
  const nextQuestionIndex = currentQuestionIndex + 1
  const questionsCount = data.quiz.questions.length
  const isAnotherQuestionFound = Boolean(nextQuestionIndex < questionsCount)

  useEffect(() => {
    setSelectedAnswer(undefined)
    setIsCorrectAnswerSelected(false)
    setShowHint(true)
    const answeredQuestions = data.getAnsweredQuestions()
    const prevAnswer = answeredQuestions.find(({ questionId }: { questionId: string }) => questionId === question.id)
    if (prevAnswer) {
      setSelectedAnswer(prevAnswer.answerIndex)
      setIsCorrectAnswerSelected(true)
    }
  }, [data, question.id])

  const renderHint = (answer: { isCorrect: boolean; hint: React.ReactNode }, answerIndex: string | undefined) => {
    if (showHint && String(selectedAnswer) === answerIndex) {
      return (
        <div className="ChoiceQuestion__answerHint">
          <img className="ChoiceQuestion__answerHintIcon" src={answer.isCorrect ? check : wrong} />
          <p className="ChoiceQuestion__answerHintText">
            {answer.isCorrect ? (
              <>
                <span>Correct! </span>
                <br />
                <strong>Pro-tip: </strong>
              </>
            ) : (
              <strong>Hint! </strong>
            )}
            {answer.hint}
          </p>
        </div>
      )
    }
  }

  const checkAnswer = () => {
    const isCorrectAnswer: boolean = question.answers[Number(selectedAnswer)].isCorrect
    setIsCorrectAnswerSelected(isCorrectAnswer)
    setShowHint(true)
    if (!isCorrectAnswerSelected) {
      setIsSubmitDisabled(true)
    }
  }

  const handleSubmit = () => {
    if (!isAnotherQuestionFound) {
      data.updateProfileFlags({ is_training_complete: true })
    }
    data.saveQuizAnswer(question.id, Number(selectedAnswer))
    if (isAnotherQuestionFound) {
      history.push({ pathname: location.pathname, search: `?questionIndex=${nextQuestionIndex}` })
    } else {
      onComplete()
    }
  }

  return (
    <div className="ChoiceQuestion">
      <div className="AcceptJob__questionContainer">
        <h3 className="AcceptJob__question">{question.question}</h3>
        <div className="ChoiceQuestion__answersSection">
          {question.answers.map((answer, i) => (
            <div key={i} className="ChoiceQuestion__answer">
              <div className="ChoiceQuestion__choice">
                <input
                  id={`answer_${i}`}
                  onChange={() => {
                    setSelectedAnswer(i)
                    setIsSubmitDisabled(false)
                    setShowHint(false)
                    setIsCorrectAnswerSelected(false)
                  }}
                  type="radio"
                  value={i}
                  checked={selectedAnswer === i}
                />
                <label htmlFor={`answer_${i}`} className="ChoiceQuestion__answerText">
                  {answer.text}
                </label>
              </div>
              {renderHint(answer, `${i}`)}
            </div>
          ))}
        </div>
      </div>
      <div className="ChoiceQuestion__steps">
        <Stepper activeStepIndex={currentQuestionIndex} stepsCount={questionsCount} />
      </div>
      {isCorrectAnswerSelected ? (
        <button className="ChoiceQuestion_nextButton" onClick={handleSubmit}>
          Next
        </button>
      ) : (
        <button disabled={isSubmitDisabled} className="ChoiceQuestion_nextButton" onClick={checkAnswer}>
          Submit
        </button>
      )}
    </div>
  )
}

export default ChoiceQuestion
