import * as React from 'react'

import Policy from '../../../components/policy/policy'
import './accept-job-policy.scss'

export type policyType = {
  headerTitle: string
  title: string
  image: string
  description: string
}

const policy: policyType[] = [
  {
    headerTitle: 'Withdrawal Policy',
    title: 'Late Withdrawals',
    image: require('../assets/policy-1.png'),
    description:
      "Withdrawal from a job less than 12 hours before the start time may result in <strong>penalities</strong> and <strong>limited</strong> access to future jobs. <br /><br />But it's always better to withdraw than No-Show.",
  },
  {
    headerTitle: 'No-Show Policy',
    title: 'No-Shows',
    image: require('../assets/policy-2.png'),
    description:
      "Not showing up to your accepted jobs is bad for everyone. <span>'No-shows'</span> result in <strong>penalities</strong>, <strong>fewer jobs</strong> and <strong>removal</strong> from accepted jobs.<br/><br/>Avoid this by withdrawing from jobs if you can't.",
  },
]

const AcceptJobPolicy = ({ onComplete }: { onComplete: () => void }) => (
  <Policy onComplete={onComplete} policy={policy} />
)

export default AcceptJobPolicy
