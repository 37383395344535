import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import './payout-choose-payment-method.scss'
import PayoutFooter from '../payout-footer/payout-footer'
import { useStore } from '../../models/utils/mst-hooks'
import { useEffect } from 'react'

interface PayoutChoosePaymentMethodInterface {
  onComplete: (option: string) => void
}

type option = {
  code: string
  text: string
}

const OPTION_BUTTONS: option[] = [
  {
    code: 'bank',
    text: 'I have my own bank account',
  },
  {
    code: 'paymax',
    text: "I don't have a bank account",
  },
]

const PayoutChoosePaymentMethod = ({ onComplete }: PayoutChoosePaymentMethodInterface) => {
  const { setPayoutData, payout } = useStore().data
  const [selectedOption, setOption] = React.useState<string | undefined>(payout?.selectedPaymentMethod)
  const [toggleErrorMessage, setToggleErrorMessage] = React.useState<boolean>(false)

  const handleOption = (option: string) => {
    setOption(option)
    setToggleErrorMessage(false)
  }

  useEffect(() => {
    if (payout?.selectedPaymentMethod) {
      handleOption(payout.selectedPaymentMethod)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const routeToNextPage = () => {
    if (selectedOption) {
      setToggleErrorMessage(false)
      setPayoutData({
        selectedPaymentMethod: selectedOption,
        account_number: '',
        name: '',
        selected_bank: '',
        swift: '',
        paymaxStatus: '',
        type: selectedOption === 'bank' ? '1' : '2',
      })
      onComplete(selectedOption)
    } else {
      setToggleErrorMessage(true)
    }
  }

  return (
    <div className="ChoosePaymentMethod">
      <Header title="Payout" />
      <div>
        <h2 className="ChoosePaymentMethod__title">How do you want to get paid?</h2>
        <div className="ChoosePaymentMethod__optionsContainer">
          {OPTION_BUTTONS.map(option => (
            <button
              key={option.code}
              className={selectedOption === option.code ? 'active' : ''}
              onClick={() => handleOption(option.code)}
            >
              {option.text}
            </button>
          ))}
          {toggleErrorMessage ? (
            <p className="ChoosePaymentMethod__errorMessage">Please Select Option to proceed</p>
          ) : null}
        </div>
      </div>
      <div className="ChoosePaymentMethod__footerContainer">
        <PayoutFooter activeStepIndex={0} handleSubmit={routeToNextPage} />
      </div>
    </div>
  )
}

export default PayoutChoosePaymentMethod
