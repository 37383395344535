import { types, flow } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { Paginator } from 'ogram-react/api/api-types'
import { DataStore } from './data-store/data-store'
import { JobModel, JobSnapshot } from './job'

export const PastJobsModel = types.model('PastJobs', {
  list: types.map(JobModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const pastJobsActions = (self: DataStore) => ({
  getPastJobs: flow(function*(firstPage?: boolean) {
    if (!firstPage && !self.pastJobs.nextPage) return

    const { list, paginator } = (yield self.request('post', 'sp/jobs/past', {
      page: firstPage ? 1 : self.pastJobs.nextPage,
    })) as { list: JobSnapshot[]; paginator?: Paginator }

    if (firstPage) {
      self.pastJobs.list.clear()
    }

    list.forEach((pastJob: JobSnapshot) => {
      self.pastJobs.list.set(String(pastJob.id), pastJob)
    })

    self.pastJobs.nextPage = paginator?.next_page ?? null
  }),
})

export const pastJobsViews = (self: DataStore) => ({
  get pastJobsArray() {
    return Array.from(self.pastJobs.list.values()).map(item => toJS(item))
  },
})
