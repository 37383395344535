import * as React from 'react'
import PayoutFooter from '../payout-footer/payout-footer'
import listBullet from '../../assets/list-bullet.svg'
import './payout-paymax-terms.scss'
import Header from '../../pages/accept-job/components/header/header'

interface PayoutPaymaxTermsProps {
  onComplete: () => void
}

const PayoutPaymaxTerms = ({ onComplete }: PayoutPaymaxTermsProps) => {
  return (
    <div className="PayoutPaymaxTerms">
      <Header title="Payout" />
      <h2 className="PayoutPaymaxTerms__title">How does it work?</h2>
      <div className="PayoutPaymaxTerms__body">
        <p className="PayoutPaymaxTerms__subText">
          Getting a Pay Max card by Lari Exchange is an easy way to get your payout from Ogram. However there are some
          fees associated with using the card.
        </p>
        <ul className="PayoutPaymaxTerms__termsList">
          <li className="PayoutPaymaxTerms__termsListItem">
            <div className="PayoutPaymaxTerms__termsListContent">
              <img src={listBullet} />
              <p>AED 15 fee to issue a new Pay Max card (one-time)</p>
            </div>
          </li>
          <li className="PayoutPaymaxTerms__termsListItem">
            <div className="PayoutPaymaxTerms__termsListContent">
              <img src={listBullet} />
              <p>AED 3 fee when you get a transfer from Ogram</p>
            </div>
          </li>
          <li className="PayoutPaymaxTerms__termsListItem">
            <div className="PayoutPaymaxTerms__termsListContent">
              <img src={listBullet} />
              <p>AED 5 fee for every time you withdraw cash</p>
            </div>
          </li>
        </ul>
      </div>
      <div className="PayoutPaymaxTerms__footerContainer">
        <PayoutFooter stepsCount={6} handleSubmit={onComplete} activeStepIndex={2} />
      </div>
    </div>
  )
}

export default PayoutPaymaxTerms
