import * as React from 'react'
import { useParams, generatePath } from 'react-router-dom'
import PayoutPaymaxTiming from '../../../components/payout-paymax-timing/payout-paymax-timing'
import { routes } from '../../../routes'
import { useHistory } from 'react-router-dom'

const AcceptJobPayoutPaymaxTiming = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const onComplete = () => history.push(generatePath(routes.acceptJobPayoutPayMaxLocation, { id }))

  return <PayoutPaymaxTiming onComplete={onComplete} />
}

export default AcceptJobPayoutPaymaxTiming
