import * as React from 'react'

// utility to create error boundary as functional components
export function createBoundary(
  Component: React.FunctionComponent<{ error: Error | null; resetError: () => void; children: React.ReactNode }>,
  config?: { didCatch?: (error: Error, errorInfo: React.ErrorInfo) => void },
) {
  return class Boundary extends React.Component<{}, { error: Error | null }> {
    state: { error: Error | null } = { error: null }
    static getDerivedStateFromError(error: Error) {
      return { error: error }
    }
    componentDidCatch = config?.didCatch
    reset = () => this.setState({ error: null })
    render() {
      return (
        <Component error={this.state.error} resetError={this.reset}>
          {this.props.children}
        </Component>
      )
    }
  }
}
