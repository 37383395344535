import * as React from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { useQuery, useStore } from '../../../models/utils/mst-hooks'
import UploadRequiredDocuments from '../../../components/upload-required-documents/upload-required-documents'
import './accept-job-required-documents.scss'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { observer } from 'mobx-react'
import { DocumentModelType } from '../../../../../common/models/job'
import { routes } from '../../../routes'
import { useEffect } from 'react'
import { useSameStepsComplete } from '../../../utils/use-same-steps-complete'
import { OverviewStatus } from '../components/overview-steps/overview-steps'

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

const AcceptJobRequiredDocuments = observer(() => {
  const { job, getJob } = useStore().data
  const { id } = useParams<{ id: string }>()
  const requiredDocuments = job?.missing_documents as DocumentModelType[]
  const jobQuery = useQuery(() => getJob(Number(id)), {
    filterError: [ApiErrorTypes.ClientError],
  })
  const history = useHistory()
  const { nextRouteObject, checkStepAsDone } = useSameStepsComplete(
    {
      pathname: routes.acceptJobProgress,
    },
    'is_missing_documents',
    'is_training_complete',
    OverviewStatus.pendingApproval,
  )

  const onUploadDocument = () => {
    getJob(Number(id))
  }

  const onUploadAllDocumnetsComplete = () => {
    checkStepAsDone()
    history.push(nextRouteObject())
  }

  useEffect(() => {
    if (!requiredDocuments.length) {
      history.push({ pathname: generatePath(routes.acceptJobProgress, { id }) })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredDocuments.length])

  return (
    <UploadRequiredDocuments
      requiredDocuments={requiredDocuments}
      onUploadDocument={onUploadDocument}
      onUploadAllDocumnetsComplete={onUploadAllDocumnetsComplete}
      isLoading={jobQuery.isLoading}
    />
  )
})

export default AcceptJobRequiredDocuments
