import * as React from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import PayoutChoosePaymentMethod from '../../../components/payout-choose-payment-method/payout-choose-payment-method'
import { routes } from '../../../routes'

const AcceptJobPayoutChoosePaymentMethod = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const onComplete = (optionCode: string) => {
    let routeUrl
    if (optionCode === 'bank') {
      routeUrl = generatePath(routes.acceptJobPayoutChooseBank, { id })
    } else {
      routeUrl = generatePath(routes.acceptJobPayoutChoosePayMaxCard, { id })
    }
    history.push(routeUrl)
  }

  return <PayoutChoosePaymentMethod onComplete={onComplete} />
}

export default AcceptJobPayoutChoosePaymentMethod
