import { observer } from 'mobx-react'
import * as React from 'react'
import { ProfileFormType } from '../../../../../common/models/profile'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { Form } from '../../../components/form/form'
import { InputField } from '../../../components/form/input/input'
import { Submit } from '../../../components/form/submit/submit'
import { useMutation, useStore } from '../../../models/utils/mst-hooks'
import './profile-form.scss'

export const ProfileForm = observer(() => {
  const store = useStore()
  const [data, setData] = React.useState<ProfileFormType>({
    ...store.data.profile.basic,
  } as ProfileFormType)
  const [saveResult, save] = useMutation(() => store.data.updateProfile(data), {
    filterError: [ApiErrorTypes.ClientError],
  })
  return (
    <div className="ProfileForm">
      <h1>Edit account</h1>
      <Form
        data={data}
        setData={setData}
        error={saveResult.error?.data}
        onSubmit={save}
        isLoading={saveResult.isLoading}
      >
        {fields => (
          <>
            <h1>Basic Information</h1>
            {saveResult.status === 'data' && <div className="ProfileForm__saved">Saved</div>}
            <>
              <InputField label="Full name" field={fields.name} required />
              <Submit>Save</Submit>
            </>
          </>
        )}
      </Form>
    </div>
  )
})
