import { cast } from 'assertio'
import { StepType } from '../models/accept-job-progress-steps'

export function filterMap<T, R>(arr: T[], fn: (val: T) => R | undefined): R[] {
  const newArr: R[] = []
  for (const el of arr) {
    const r = fn(el)
    if (r !== undefined) {
      newArr.push(r)
    }
  }
  return newArr
}

export function objectKeys<T extends object>(obj: T) {
  return Object.keys(obj) as (keyof T)[]
}

export function range(from: number, to: number, step = 1) {
  const arr: number[] = []
  for (let i = from; i <= to; i += step) {
    arr.push(i)
  }
  if (from <= to && arr[arr.length - 1] !== to) {
    arr.push(to)
  }
  return arr
}

export function resetTime(date: Date) {
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  date.setMilliseconds(0)
  return date
}

export type DeepPartial<T extends object> = {
  [P in keyof T]?: T[P] extends object ? (T[P] extends Date ? T[P] : DeepPartial<T[P]>) : T[P]
}

export function deepUpdate<T extends object>(obj: T, partial: DeepPartial<T>) {
  const newObj = { ...obj }
  for (const key in partial) {
    const k = key as keyof T
    const val = partial[key] as unknown
    newObj[k] = (isObject(val) && !(val instanceof Date) ? deepUpdate(cast(obj[k]), val) : val) as T[keyof T]
  }
  return newObj
}

export function objectMap<T, V>(obj: T, fn: (key: keyof T, value: T[keyof T]) => V) {
  const newObj = {} as Record<keyof T, V>
  for (const key in obj) {
    newObj[key] = fn(key, obj[key])
  }
  return newObj
}

export function pick<T extends object, K extends keyof T>(obj: T, keys: K[]) {
  const newObj = {} as T
  for (const key in obj) {
    if ((keys as string[]).indexOf(key) > -1) {
      newObj[key] = obj[key]
    }
  }
  return newObj as { [P in K]: T[P] }
}

export function omit<T extends object, K extends keyof T>(obj: T, keys: K[]) {
  const newObj = {} as T
  for (const key in obj) {
    if ((keys as string[]).indexOf(key) === -1) {
      newObj[key] = obj[key]
    }
  }
  return newObj as { [P in Exclude<keyof T, K>]: T[P] }
}

export function isObject(obj: unknown): obj is object {
  return typeof obj === 'object' && obj !== null
}

export const snakeToCamel = (str: string) =>
  str.replace(/([-_][a-z])/g, group =>
    group
      .toUpperCase()
      .replace('-', '')
      .replace('_', ''),
  )

export const findStepByPropKey = (array: StepType[], propKey: string) => array.find(step => step.propKey === propKey)
