import { observer } from 'mobx-react'
import * as React from 'react'
import { Link } from 'react-router-dom'
import { routes } from '../../../routes'
import './side-bar.scss'

export const SideBar = observer((props: {}) => {
  return (
    <div className="SideBar">
      <div className="SideBar__row">
        <div className="SideBar__group">
          <div className="SideBar__groupName">Profile</div>
          <div className="SideBar__groupItems">
            <div className="SideBar__row">
              <Link to={routes.editProfile}>Edit Account</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})
