import { types, flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export enum PaymentMethodIds {
  bank = 1,
  paymax = 2,
}

export type PayoutPostRequestData = {
  type: PaymentMethodIds
  bank_id?: number
  name: string
  account_number: string
  country?: string
  address?: string
}

export type PaymaxPickUpLocation = {
  branch_id: number | undefined
}

export const PayoutModel = types.model('Payout', {
  type: types.optional(types.string, ''),
  selected_bank: types.optional(types.string, ''),
  name: types.optional(types.string, ''),
  account_number: types.optional(types.string, ''),
  selectedPaymentMethod: types.optional(types.string, ''),
  swift: types.optional(types.string, ''),
  paymaxStatus: types.optional(types.string, ''),
  selected_region: types.optional(types.string, ''),
  selectedLariExchangeLocation: types.optional(types.string, ''),
  branch_id: types.maybeNull(types.number),
  address: types.optional(types.string, 'a'),
  country: types.optional(types.string, 'UAE'),
})

export const payoutActions = (self: DataStore) => ({
  setPayoutData: (payoutData: { [key: string]: string | number | undefined }) => {
    if (self.payout) {
      self.payout = { ...self.payout, ...payoutData }
    } else {
      self.payout = ({ ...payoutData } as unknown) as Payout
    }
  },
  updatePayoutMethod: flow(function*(payoutData: PayoutPostRequestData) {
    yield self.request('post', 'sp/payout-method', payoutData)
  }),
  requestPaymaxCard: flow(function*(pickUpLocation: PaymaxPickUpLocation) {
    yield self.request('post', 'sp/lari-exchange/card', pickUpLocation)
  }),
  validatePayoutData: flow(function*(payoutData) {
    yield self.request('post', 'sp/payout-method/validate', payoutData)
  }),
})

export type Payout = typeof PayoutModel.Type
export type PayoutSnapshot = typeof PayoutModel.SnapshotType
