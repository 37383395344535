import { types, flow } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { Paginator } from 'ogram-react/api/api-types'
import { DataStore } from './data-store/data-store'
import { JobModel, JobSnapshot } from './job'

export const AppliedJobsModel = types.model('AppliedJobs', {
  list: types.map(JobModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const appliedJobsActions = (self: DataStore) => ({
  getAppliedJobs: flow(function*(firstPage?: boolean) {
    if (!firstPage && !self.appliedJobs.nextPage) return

    const { list, paginator } = (yield self.request('post', 'sp/jobs/applied', {
      page: firstPage ? 1 : self.appliedJobs.nextPage,
    })) as { list: JobSnapshot[]; paginator?: Paginator }

    if (firstPage) {
      self.appliedJobs.list.clear()
    }

    list.forEach((appliedJob: JobSnapshot) => {
      self.appliedJobs.list.set(String(appliedJob.id), appliedJob)
    })

    self.appliedJobs.nextPage = paginator?.next_page ?? null
  }),
})

export const apliedJobsViews = (self: DataStore) => ({
  get appliedJobsArray() {
    return Array.from(self.appliedJobs.list.values()).map(item => toJS(item))
  },
})
