import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export enum PayoutMethodStatus {
  pending = 1,
  paymaxCardRequired = 2,
  active = 3,
  paymaxCardForceRequired = 4,
  paymaxCardRequestExported = 5,
}

export enum UserPayoutType {
  paymax = 'paymax',
  bankAccount = 'bankAccount',
}

const PaymaxCardModel = types.model('PaymaxCard', {
  id: types.number,
  account_number: types.string,
  status_id: types.number,
  branch_name: types.string,
})

const BankAccountModel = types.model('BankAccount', {
  id: types.number,
  status_id: types.number,
  account_number: types.string,
  bank_id: types.number,
  address: types.string,
  country: types.string,
  name: types.string,
})

export const UserPayoutModel = types.model('UserPayout', {
  type: types.enumeration<UserPayoutType>('UserPayoutType', Object.values(UserPayoutType)),
  paymax: types.maybeNull(PaymaxCardModel),
  bankAccount: types.maybeNull(BankAccountModel),
})

export const userPayoutActions = (self: DataStore) => ({
  getPayoutDetails: flow(function*() {
    const { type, ...payoutData } = (yield self.request('get', 'sp/payout-method/details')) as {
      id: number
      type: number
      status_id: number
      account_number?: string
      branch_name?: string
      name?: string
      bank_id?: number
      address?: string
      country?: string
    }
    switch (type) {
      case 1:
        self.userPayout = {
          type: UserPayoutType.bankAccount,
          paymax: null,
          bankAccount: payoutData as BankAccountSnapshot,
        }
        break
      case 2:
        self.userPayout = {
          type: UserPayoutType.paymax,
          paymax: payoutData as PaymaxCardSnapshot,
          bankAccount: null,
        }
        break
      default:
        throw new Error('Unrecognized payout type')
    }
  }),
})

export const userPayoutViews = (self: DataStore) => ({
  get payoutDetails(): PaymaxCardSnapshot | BankAccountSnapshot | null {
    if (!self.userPayout) {
      return null
    }

    switch (self.userPayout.type) {
      case UserPayoutType.paymax:
        return self.userPayout.paymax
      case UserPayoutType.bankAccount:
        return self.userPayout.bankAccount
      default:
        return null
    }
  },
})

export type PaymaxCard = SnapshotIn<typeof PaymaxCardModel>
export type PaymaxCardSnapshot = SnapshotOut<typeof PaymaxCardModel>

export type BankAccount = SnapshotIn<typeof BankAccountModel>
export type BankAccountSnapshot = SnapshotOut<typeof BankAccountModel>

export type UserPayout = SnapshotIn<typeof UserPayoutModel>
export type UserPayoutSnapshot = SnapshotOut<typeof UserPayoutModel>
