import * as React from 'react'
import { observer } from 'mobx-react'
import './quiz-question.scss'
import { QuestionDataType } from '../../../../common/models/quiz'
import Header from '../../pages/accept-job/components/header/header'
import ChoiceQuestion from '../choice-question/choice-question'

interface QuizQuestionProps {
  question: QuestionDataType
  onComplete: () => void
}

const QuizQuestion = observer(({ question, onComplete }: QuizQuestionProps) => {
  return (
    <div className="Quiz">
      <Header title="Quiz" />
      <ChoiceQuestion onComplete={onComplete} question={question} />
    </div>
  )
})

export default QuizQuestion
