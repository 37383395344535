import * as React from 'react'
import './header.scss'
import back from '../../assets/back.svg'
import { useHistory } from 'react-router-dom'

interface HeaderProps {
  title: string
}

const Header = (props: HeaderProps) => {
  const { title } = props
  const history = useHistory()
  const goBack = () => history.goBack()
  return (
    <div className="JobOverview__header">
      <img className="JobOverview__backButton" onClick={goBack} src={back} alt="back to previous screen" />
      <h3 className="JobOverview__headerTitle">{title}</h3>
    </div>
  )
}

export default Header
