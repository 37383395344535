import { types, flow } from 'mobx-state-tree'
import { values } from 'mobx'
import { DataStore } from './data-store/data-store'
import { CommonFile } from '../types'

const ProfileModel = types.model('Profile', {
  id: types.identifierNumber,
  name: types.string,
  status_id: types.number,
  is_basic_info_complete: types.boolean,
  is_experience_complete: types.boolean,
  is_documents_complete: types.boolean,
  is_contract_complete: types.boolean,
  is_policies_complete: types.boolean,
  is_training_complete: types.boolean,
  is_payout_method_complete: types.boolean,
  email_verified_at: types.maybeNull(types.number),
})

const ProfileDetailsModel = types.model('ProfileDetails', {
  id: types.number,
  first_name: types.string,
  last_name: types.string,
  email: types.string,
  phone: types.string,
  image: types.maybeNull(types.string),
  gender: types.maybeNull(types.number),
  height: types.maybeNull(types.number),
  date_of_birth: types.maybeNull(types.string),
  jobs_count: types.maybeNull(types.number),
  likes_count: types.maybeNull(types.number),
  rating: types.maybeNull(types.number),
  emirates_id_no: types.maybeNull(types.string),
  emirates_id_expiry: types.maybeNull(types.string),
  nationality: types.maybeNull(
    types.model({
      id: types.number,
      name: types.string,
    }),
  ),
  languages: types.optional(
    types.array(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    [],
  ),
  preference_locations: types.optional(
    types.array(
      types.model({
        id: types.number,
        name: types.string,
      }),
    ),
    [],
  ),
})

export const ProfileStoreModel = types.model('ProfileStore', {
  basic: types.maybeNull(ProfileModel),
  details: types.maybeNull(ProfileDetailsModel),
})

export const profileActions = (self: DataStore) => ({
  getProfile: flow(function*(): Generator<Promise<Profile>> {
    self.profile.basic = (yield self.request('post', 'sp/profile/basic')) as Profile
  }),

  getProfileDetails: flow(function*() {
    self.profile.details = (yield self.request('post', 'sp/profile/basic/details')) as ProfileDetails
  }),

  getProfileContractLink: flow(function*() {
    return (yield self.request('post', 'sp/contract')) as { contract_url: string }
  }),

  updateProfile: flow(function*(data: ProfileFormType) {
    yield self.request('post', 'sp/profile/basic/update', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
    self.profile.basic = { ...data }
  }),

  updateProfileFlags: flow(function*(data: { [key: string]: boolean }) {
    yield self.request('post', 'sp/profile/basic/update', data)
    self.profile.basic = { ...self.profile.basic, ...data } as ProfileFormType
  }),

  updateProfileDetails: flow(function*(data: ProfileDetailsForm) {
    yield self.request('post', 'sp/profile/basic/update', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
  }),

  setProfileImageLocally: flow(function*(image: string) {
    if (self.profile.details) {
      self.profile.details.image = image
    }
  }),

  deactivateProfile: flow(function*() {
    try {
      yield self.request('post', 'sp/availability/status', { status: 0 })
    } catch (error) {
      if (error.data.code === 'availability_job_conflict') {
        const shift_ids = error.data.errors.reduce(
          (acc: Record<string, number>, conflictShift: { id: number }, index: number) => {
            acc[`shift_ids[${index}]`] = conflictShift.id
            return acc
          },
          {},
        )
        yield self.request(
          'post',
          'sp/shifts/cancel',
          { ...shift_ids, reason_details: 'Resolving conflicts' },
          { headers: { 'Content-Type': 'multipart/form-data' } },
        )
        yield self.request('post', 'sp/availability/status', { status: 0 })
      } else {
        throw error
      }
    }
  }),

  cleanProfile: () => {
    self.profile.basic = null
    self.profile.details = null
  },
})

export const profileViews = (self: DataStore) => ({
  get userWorkPlacesArray() {
    return values(self.profile.details?.preference_locations) as { id: number; name: string }[] | null
  },

  get userLanguagesArray() {
    return values(self.profile.details?.languages) as { id: number; name: string }[] | null
  },
})

export type Profile = typeof ProfileModel.Type
export type ProfileSnapshot = typeof ProfileModel.SnapshotType
export type ProfileFormType = Omit<Profile, 'image'> & {
  image: CommonFile | null | undefined
  image_url: string | null
}

export type ProfileDetails = typeof ProfileDetailsModel.Type
export type ProfileDetailsSnapshot = typeof ProfileDetailsModel.SnapshotType
export type ProfileDetailsForm = Partial<Omit<ProfileDetailsSnapshot, 'image'>> & {
  image?: CommonFile | null | undefined
}

export type ProfileStore = typeof ProfileStoreModel.Type
export type ProfileStoreSnapshot = typeof ProfileStoreModel.SnapshotType

export enum ProfileStatus {
  notSubmitted = 1,
  pendingReview = 2,
  active = 5,
  rejected = 6,
}
