import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import PayoutFooter from '../payout-footer/payout-footer'

import './payout-paymax-options.scss'

interface PayoutPaymaxOptionsInterface {
  onComplete: () => void
}

const PayoutPaymaxOptions = ({ onComplete }: PayoutPaymaxOptionsInterface) => {
  return (
    <div className="PayoutPaymaxOptions">
      <Header title="Payout" />
      <div>
        <h2 className="PayoutPaymaxOptions__title">
          Pay Max card <br /> by Lari Exchange
        </h2>
        <p className="PayoutPaymaxOptions__hint">
          Your only alternative to getting payout through your Bank is via our partner Lari Exchange.
        </p>
      </div>
      <div className="PayoutPaymaxOptions__footerContainer">
        <PayoutFooter activeStepIndex={1} handleSubmit={onComplete} />
      </div>
    </div>
  )
}

export default PayoutPaymaxOptions
