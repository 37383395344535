import { types, flow } from 'mobx-state-tree'
import { toJS } from 'mobx'
import { Paginator } from 'ogram-react/api/api-types'
import { DataStore } from './data-store/data-store'
import { JobModel, JobSnapshot } from './job'

export const InvitedJobsModel = types.model('InvitedJobs', {
  list: types.map(JobModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
  total: types.maybeNull(types.number),
})

export const invitedJobsActions = (self: DataStore) => ({
  getInvitedJobs: flow(function*(firstPage?: boolean) {
    if (!firstPage && !self.invitedJobs.nextPage) return

    const { list, paginator } = (yield self.request('post', 'sp/invitations/list', {
      page: firstPage ? 1 : self.invitedJobs.nextPage,
    })) as { list: JobSnapshot[]; paginator?: Paginator }

    if (firstPage) {
      self.invitedJobs.list.clear()
    }

    list.forEach((invitedJob: JobSnapshot) => {
      self.invitedJobs.list.set(String(invitedJob.id), invitedJob)
    })

    self.invitedJobs.nextPage = paginator?.next_page ?? null
    self.invitedJobs.total = paginator?.total ?? null
    if (self.bookedShifts) {
      self.bookedShifts.active_invitations_count = paginator?.total ?? 0
    }
  }),
})

export const invitedJobsViews = (self: DataStore) => ({
  get invitedJobsArray() {
    return Array.from(self.invitedJobs.list.values()).map(item => toJS(item))
  },

  get invitationsCount() {
    return self.bookedShifts?.active_invitations_count
  },
})
