import { FormError } from 'ogram-react/api/api-types'
import { formatDateDayMonth } from './date-utils'

export interface FormatedJobErrror {
  job_region: string | undefined
  job_total_job_hours: string | undefined
  job_start_date: string | undefined
  job_end_date: string | undefined
  job_work_category_name: string | undefined
  job_expected_hour_rate: string | undefined
}

export const formatJobDetailsError = (jobError: FormError | undefined): FormatedJobErrror => {
  return {
    job_region: jobError?.job_region[0],
    job_total_job_hours: jobError?.job_total_job_hours[0],
    job_start_date: formatDateDayMonth(jobError?.job_start_date[0]),
    job_end_date: formatDateDayMonth(jobError?.job_end_date[0]),
    job_work_category_name: jobError?.job_work_category_name[0],
    job_expected_hour_rate: jobError?.job_expected_hour_rate[0],
  }
}
