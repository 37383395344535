import { load, save } from '../../utils/storage'
import { ApiConfig } from 'ogram-react/api/api-config'
import { ApiRest as ApiRestCommon } from 'ogram-react/api/api-rest'
const queryString = require('query-string')

export class ApiRest extends ApiRestCommon {
  AUTH_SORAGE_KEY = 'AUTH_TOKEN'

  authorizationKey?: string | null

  constructor(config: ApiConfig) {
    super(config)
    const queryToken = queryString.parse(location.search).token
    if (queryToken) {
      this.setAuthKey(`Bearer ${queryToken}`)
    }
    config.requestInterceptors?.forEach(interceptor => {
      this.api.interceptors.request.use(interceptor.bind(this))
    })

    config.responseInterceptors?.forEach(interceptor => {
      this.api.interceptors.response.use(interceptor.bind(this))
    })
  }

  async setAuthKey(authorizationKey: string) {
    this.authorizationKey = authorizationKey
    return await Promise.resolve(save(this.AUTH_SORAGE_KEY, authorizationKey))
  }

  async getAuthKey() {
    if (this.authorizationKey) {
      return this.authorizationKey
    }
    this.authorizationKey = load(this.AUTH_SORAGE_KEY) as string
    return await Promise.resolve(this.authorizationKey)
  }
}
