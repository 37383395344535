import { types, flow } from 'mobx-state-tree'
import { Paginator } from 'ogram-react/api/api-types'
import { DataStore } from './data-store/data-store'
import { JobModel } from './job'

export const OpportunityStoreModel = types.model('OpportunityStore', {
  list: types.map(JobModel),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const opportunityActions = (self: DataStore) => ({
  getOpportunities: flow(function*(firstPage?: boolean) {
    if (!firstPage && !self.opportunity.nextPage) return

    const { list: opportunities = [], paginator } = (yield self.request('post', 'sp/opportunities', {
      page: firstPage ? 1 : self.opportunity.nextPage,
    })) as { list: OpportunitySnapshot[]; paginator?: Paginator }

    if (firstPage) {
      self.opportunity.list.clear()
    }

    opportunities.forEach((opportunity: OpportunitySnapshot) => {
      self.opportunity.list.set(String(opportunity.id), opportunity)
    })

    self.opportunity.nextPage = paginator?.next_page ?? null
  }),
})

export const opportunityViews = (self: DataStore) => ({
  get opportunities() {
    return Array.from(self.opportunity.list.values())
  },
})

export type Opportunity = typeof JobModel.Type
export type OpportunitySnapshot = typeof JobModel.SnapshotType

export type OpportunityStore = typeof OpportunityStoreModel.Type
export type OpportunityStoreSnapshot = typeof OpportunityStoreModel.SnapshotType
