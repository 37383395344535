import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import './payout-name.scss'
import PayoutFooter from '../payout-footer/payout-footer'
import TextInput from '../text-input/text-input'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import { useStore } from '../../models/utils/mst-hooks'

export interface IbanForm {
  iban: string
}

interface PayoutNameInterface {
  onComplete: () => void
}

const PayoutName = ({ onComplete }: PayoutNameInterface) => {
  const { payout, setPayoutData } = useStore().data
  return (
    <div className="PayoutName">
      <Header title="Payout" />
      <h2 className="PayoutName__title">Enter the exact name of the account holder</h2>
      <Formik
        initialValues={{
          name: payout?.name,
        }}
        onSubmit={values => {
          setPayoutData(values)
          onComplete()
        }}
        validationSchema={Yup.object().shape({
          name: Yup.string().required('This field is required'),
        })}
      >
        {formikProps => (
          <Form className="PayoutName__formBody" noValidate>
            <TextInput label="Full Name" formikKey="name" formikProps={formikProps} />
            <div className="PayoutName__footerContainer">
              <PayoutFooter activeStepIndex={3} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default PayoutName
