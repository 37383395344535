import { flow, getEnv, applySnapshot } from 'mobx-state-tree'
import { Enviroment } from 'ogram-react/mst/environment/environment'

import { DataStore } from './data-store/data-store'
import { ProfileSnapshot } from './profile'
import { CommonFile } from '../types'

export const authActions = (self: DataStore) => {
  const actions = {
    login: flow(function*(params: SignInDataType) {
      self.profile.basic = (yield self.request('post', 'sp/auth/login', params)) as ProfileSnapshot
    }),

    signUp: flow(function*(params: SignUpDataType) {
      self.profile.basic = (yield self.request('post', 'sp/auth/signup', params)) as ProfileSnapshot
    }),

    logout: flow(function*(params: { device_id: string }) {
      yield self.request('post', 'sp/auth/logout', params)
      actions.logoutLocal()
    }),

    loginLocal: function() {
      self.logged = true
    },

    logoutLocal: function() {
      getEnv<Enviroment>(self).api.setAuthKey(null)
      applySnapshot(self, {
        profile: {
          basic: null,
          details: null,
        },
      })
    },

    refreshToken: flow(function*() {
      yield self.request('post', 'sp/auth/refresh')
    }),

    refreshFirebaseToken: flow(function*(params: { device_id: string; firebase_token: string }) {
      yield self.request('post', 'sp/auth/firebase/refresh', params)
    }),

    restorePassword: flow(function*(params: { email: string }) {
      yield self.request('post', 'sp/auth/forgot_password', params)
    }),

    resendEmailVerification: flow(function*(params: { email: string }) {
      yield self.request('post', 'sp/auth/resend_email_verification', params)
    }),

    changeEmail: flow(function*(params: { email: string }) {
      yield self.request('post', 'common/user/email', params)
    }),

    verifyEmiratesID: flow(function*(params: { emirates_id: string; expiry_date: string }) {
      const { email } = (yield self.request('post', 'sp/auth/forgot_password/emirates', params)) as { email: string }
      return email
    }),

    restoreAccess: flow(function*(
      data: { old_email: string; new_email: string; photo: CommonFile },
      onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void,
    ) {
      yield self.request('post', 'sp/auth/restore_access', data, {
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress,
      })
    }),
  }

  return actions
}

export type SignInDataType = { email: string; password: string; device_id: string; firebase_token: string }

export type SignUpDataType = {
  first_name: string
  last_name: string
  phone: string
  email: string
  password: string
  firebase_token: string
  device_id: string
}
