export const convertMilisecondsClock = (milisecondsTime: number) => {
  const date = new Date(milisecondsTime)
  const hours: string | number = date.getHours() > 12 ? date.getHours() - 12 : date.getHours()
  const am_pm = date.getHours() >= 12 ? 'PM' : 'AM'
  const minutes = date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  return `${hours}:${minutes}${am_pm}`
}

export const formatDate = (milisecondsTime: number) => {
  const date = new Date(milisecondsTime)
  const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

  const day = days[date.getDay()]
  const month = months[date.getMonth()]
  let dateDay: number | string = date.getDate()
  dateDay = dateDay > 9 ? dateDay : `0${dateDay}`
  return { day: `${day},`, date: `${month} ${dateDay}` }
}

export const formatDateDayMonth = (stringDate: string | undefined) => {
  if (stringDate) {
    const date = new Date(stringDate)
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']

    const month = months[date.getMonth()]
    let dateDay: number | string = date.getDate()
    dateDay = dateDay > 9 ? dateDay : `0${dateDay}`
    return `${month} ${dateDay}`
  }
  return
}
