import React from 'react'
import { DeepPartial, deepUpdate } from '../../../common/utils/common'

export function useFormState<T extends object>(initialState: T) {
  const [state, setState] = React.useState(initialState)
  const setFormState = (partState: DeepPartial<T>) => {
    setState(deepUpdate(state, partState))
  }
  return [state, setFormState] as const
}
