import { types, flow, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'
import { values } from 'mobx'

export enum JobStatus {
  draft = 1,
  pendingApproval = 2,
  published = 3,
  started = 4,
  completed = 5,
  cancelled = 6,
}

export enum ShiftStatus {
  irrelevant = 0,
  accepted = 1,
  completed = 4,
  canceled = 5,
  started = 7,
  pendingConfirmation = 8,
}

export const ShiftModel = types.model('Shift', {
  id: types.number,
  hours: types.number,
  sp_active_shift_id: types.maybeNull(types.number),
  start_time: types.number,
  end_time: types.number,
  time_to_start: types.number,
  time_to_end: types.number,
  status_id: types.maybeNull(types.number),
  status: types.maybeNull(types.string),
  client_checkin_time: types.maybeNull(types.number),
  client_checkout_time: types.maybeNull(types.number),
  sp_checkin_time: types.maybeNull(types.number),
  sp_checkout_time: types.maybeNull(types.number),
})

export const LocationModel = types.model('Location', {
  lat: types.number,
  lng: types.number,
  name: types.string,
  region: types.maybeNull(types.string),
  image: types.maybeNull(types.string),
  address: types.maybeNull(types.string),
})

export const FlagsJobModel = types.model('Flags', {
  showInvite: types.maybeNull(types.number),
  showApply: types.maybeNull(types.number),
  showAccept: types.maybeNull(types.number),
  showWithdraw: types.maybeNull(types.number),
  showCancel: types.maybeNull(types.number),
  showConfirmation: types.maybeNull(types.number),
  showCheckIn: types.maybeNull(types.number),
  showCheckOut: types.maybeNull(types.number),
  isRequestedCheckin: types.maybeNull(types.number),
  isRequestedCheckout: types.maybeNull(types.number),
})

const ClientModel = types.model('Client', {
  phone: types.string,
  image_url: types.string,
})

const RequirementModel = types.model('RequirementModel', {
  id: types.number,
  name: types.string,
})

const DocumentModel = types.model('DocumentModel', {
  display_name: types.string,
  document_identifier: types.maybeNull(types.string),
  expiry_date: types.maybeNull(types.string),
  file: types.maybeNull(types.string),
  id: types.maybeNull(types.number),
  issue_date: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  status: types.number,
  type: types.number,
})

export const JobDetailsModel = types.model('JobDetails', {
  shifts: types.array(ShiftModel),
  location: types.maybeNull(LocationModel),
  title: types.string,
  price: types.maybeNull(types.number),
  currency: types.string,
  invited: types.boolean,
  time_to_accept: types.maybeNull(types.number),
  order_name: types.string,
  work_category_name: types.string,
  client_location: types.string,
  description: types.string,
  event_description: types.string,
  start_date: types.string,
  end_date: types.string,
  uniform_provided: types.string,
  instructions: types.string,
  is_automatic_checkout: types.number,
  actual_sp_hours: types.number,
  expected_amount: types.number,
  hourly_rate: types.number,
  hours: types.number,
  status_id: types.number,
  interested: types.boolean,
  transportation_rate: types.maybeNull(types.number),
  order_channel_sid: types.maybeNull(types.string),
  flags: FlagsJobModel,
  client: ClientModel,
  work_requirements: types.array(RequirementModel),
  missing_documents: types.array(DocumentModel),
  client_image: types.maybeNull(types.string),
  start_time: types.maybeNull(types.number),
  hours_per_day: types.maybeNull(types.number),
  daily_rate: types.maybeNull(types.number),
  total_daily_rate: types.maybeNull(types.number),
  transportation_provided: types.maybeNull(types.boolean),
})

export const JobModel = types.model('Job', {
  id: types.identifierNumber,
  start_date: types.string,
  end_date: types.string,
  price: types.maybeNull(types.number),
  address: types.string,
  first_and_last_shifts: types.optional(
    types.array(
      types.model('FirstAndLastShifts', {
        start_time: types.number,
        end_time: types.number,
      }),
    ),
    [],
  ),
  instructions: types.maybeNull(types.string),
  currency: types.string,
  work_category_name: types.string,
  shifts_count: types.number,
  assignment_type: types.maybeNull(types.number),
  location: types.maybeNull(LocationModel),
  client_image: types.maybeNull(types.string),
  start_time: types.maybeNull(types.number),
  hours_per_day: types.maybeNull(types.number),
  daily_rate: types.maybeNull(types.number),
  transportation_provided: types.maybeNull(types.boolean),
})

export const jobActions = (self: DataStore) => {
  return {
    getJob: flow(function*(jobId: number, shiftId?: number) {
      const params: Record<string, number> = { id: jobId }
      if (shiftId !== undefined) {
        params.shift_id = shiftId
      }
      const job = (yield self.request('post', 'sp/home/jobs/display', params)) as JobDetailsModelType
      self.job = job
      return job
    }),

    clearJob: flow(function*() {
      self.job = null
    }),

    saveJob: flow(function*({ jobId, confirmed }: { jobId: number; confirmed: number }) {
      yield self.request('post', `sp/jobs/save?confirmed=${confirmed}`, { job_id: jobId })
    }),

    acceptInvitation: flow(function*({ jobId, confirmed }: { jobId: number; confirmed: number }) {
      yield self.request('post', `sp/invitations/accept`, { job_id: jobId, confirmed })
    }),

    rejectInvitation: flow(function*(params: { job_id: number } & Record<string, number | string>) {
      yield self.request('post', `sp/invitations/reject`, params, {
        headers: { 'Content-Type': 'multipart/form-data' },
      })
    }),

    unsaveJob: flow(function*({ jobId }: { jobId: number }) {
      yield self.request('post', `sp/jobs/unsave`, { job_id: jobId })
    }),

    startShift: flow(function*({ shiftId, lat, lng }: CheckInOut) {
      yield self.request('post', `sp/shifts/check-in`, { shift_id: shiftId, lat, lng })
    }),

    completeShift: flow(function*({ shiftId, lat, lng }: CheckInOut) {
      yield self.request('post', `sp/shifts/check-out`, { shift_id: shiftId, lat, lng })
    }),

    cancelShifts: flow(function*({
      shiftIds,
      reason,
      reasonId,
    }: {
      shiftIds: number[]
      reason: string
      reasonId: number
    }) {
      const idParams = shiftIds.reduce<Record<string, number>>((acc, shiftId, index) => {
        acc[`shift_ids[${index}]`] = shiftId
        return acc
      }, {})
      yield self.request(
        'post',
        'sp/shifts/cancel',
        { ...idParams, reason_details: reason, 'reasons_id[0]': reasonId },
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      )
    }),
  }
}
export const jobViews = (self: DataStore) => ({
  getShiftsList() {
    return values(self.job?.shifts) as ShiftModelType[]
  },
})

export type CheckInOut = { shiftId: number; lat: number; lng: number }

export type ShiftModelType = typeof ShiftModel.Type
export type ShiftSnaphot = SnapshotOut<typeof ShiftModel>

export type JobDetailsModelType = typeof JobDetailsModel.Type
export type JobDetailsSnaphot = SnapshotOut<typeof JobDetailsModel>

export type DocumentModelType = typeof DocumentModel.Type
export type DocumentSnapshot = SnapshotOut<typeof DocumentModel>

export type JobType = typeof JobModel.Type
export type JobSnapshot = SnapshotOut<typeof JobModel>

export type RequirementSnaphot = SnapshotOut<typeof RequirementModel>
