import * as React from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import PayoutName from '../../../components/payout-name/payout-name'
import { routes } from '../../../routes'

const AcceptJobPayoutName = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const onComplete = () => history.push(generatePath(routes.acceptJobPayoutTerms, { id }))

  return <PayoutName onComplete={onComplete} />
}

export default AcceptJobPayoutName
