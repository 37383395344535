import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

type Action = Generator<Promise<unknown>, void>

export const ProfessionModel = types.model('Profession', {
  id: types.identifierNumber,
  name: types.string,
  image: types.string,
})

const ProfessionDetailsModel = types.model('ProfessionDetails', {
  id: types.identifierNumber,
  work_category_id: types.number,
  name: types.string,
  min_years: types.number,
  max_years: types.number,
  actual_years: types.number,
  min_hour_rate: types.number,
  shift_percentage: types.number,
  weekly_percentage: types.number,
  monthly_percentage: types.number,
  max_hour_rate: types.number,
  actual_hour_rate: types.number,
  shift_rate: types.number,
  weekly_rate: types.number,
  monthly_rate: types.number,
  work_locations: types.array(
    types.model('Location', {
      id: types.number,
      name: types.string,
      lat: types.number,
      lng: types.number,
    }),
  ),
  selectedPlaces: types.array(
    types.model('WorkPlace', {
      name: types.string,
      lat: types.number,
      lng: types.number,
    }),
  ),
})

export const professionActions = (self: DataStore) => ({
  getProfessions: flow(function*(): Action {
    const professions = (yield self.request('get', `common/professions`)) as ProfessionSnapshot[]
    professions.forEach(profession => {
      self.professions.set(String(profession.id), profession)
    })
  }),

  getProfessionDetails: flow(function*(work_category_id: number) {
    return (yield self.request('post', 'sp/profile/experience', { work_category_id })) as ProfessionDetailsSnapshot
  }),
})

export const professionViews = (self: DataStore) => ({
  getProfession(id: string) {
    return self.professions.get(id)
  },

  getProfessionIdByTitle(title: string) {
    return Array.from(self.professions.values()).find(profession => profession.name === title)?.id
  },

  getProfessionName(id: number) {
    return Array.from(self.professions.values()).find(role => role.id === id)?.name as string
  },

  get professionList() {
    return Array.from(self.professions.values())
  },
})

export type Profession = typeof ProfessionModel.Type
export type ProfessionSnapshot = typeof ProfessionModel.SnapshotType

export type ProfessionDetails = typeof ProfessionDetailsModel.Type
export type ProfessionDetailsSnapshot = typeof ProfessionDetailsModel.SnapshotType
