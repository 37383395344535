import { types, flow, SnapshotIn, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const BankModel = types.model('BankModel', {
  id: types.number,
  name: types.string,
  code: types.string,
  bank_code: types.string,
  pay_mode: types.string,
})

export const bankActions = (self: DataStore) => ({
  getBanks: flow(function*() {
    const banks = (yield self.request('post', 'common/banks', {})) as BankSnapshot[]
    banks.forEach(bank => {
      self.banks.set(String(bank.id), bank)
    })
  }),
})

export const bankViews = (self: DataStore) => ({
  getBank(id: number) {
    return self.banks.get(String(id)) as BankSnapshot
  },

  getBanksList() {
    return Array.from(self.banks.values())
  },
})

export type BankType = SnapshotIn<typeof BankModel>
export type BankSnapshot = SnapshotOut<typeof BankModel>
