import { generatePath, useParams } from 'react-router-dom'
import { findStepByPropKey } from '../../../common/utils/common'
import { useStore } from '../models/utils/mst-hooks'
import { OverviewStatus } from '../pages/accept-job/components/overview-steps/overview-steps'

export const useSameStepsComplete = (
  nextRoute: { pathname: string; search?: string },
  completedStepKey: string,
  nexStepName?: string,
  doneStatus?: string,
) => {
  const { getSteps, setSteps } = useStore().data
  const { id } = useParams<{ id: string }>()

  return {
    nextRouteObject: () => ({
      pathname: generatePath(nextRoute.pathname, {
        id,
      }),
      search: nextRoute.search,
    }),
    checkStepAsDone: () => {
      const steps = JSON.parse(JSON.stringify(getSteps()))
      const step = findStepByPropKey(steps, completedStepKey)
      if (nexStepName) {
        const nextStep = findStepByPropKey(steps, nexStepName)
        if (nextStep) {
          nextStep.status = OverviewStatus.active
        }
      }
      if (step) {
        step.status = doneStatus ?? OverviewStatus.done
      }
      setSteps(steps)
    },
  }
}
