import * as React from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import PayoutIban from '../../../components/payout-iban/payout-iban'
import { routes } from '../../../routes'

const AcceptJobPayoutIban = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const onComplete = () => history.push(generatePath(routes.acceptJobPayoutName, { id }))

  return <PayoutIban onComplete={onComplete} />
}

export default AcceptJobPayoutIban
