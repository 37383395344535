import axios from 'axios'
import { Api } from 'ogram-react/api/api'
import { DEFAULT_API_CONFIG } from '../../../common/services/api/api-config'
import { ApiRest } from '../services/api/api-rest'

axios.defaults.withCredentials = true

/**
 * The environment is a place where services and shared dependencies between
 * models live.  They are made available to every model via dependency injection.
 */
export class Environment {
  /**
   * Our api.
   */
  api: Api = new ApiRest({ ...DEFAULT_API_CONFIG, withCredentials: true })

  rawApi = axios.create({})
}
