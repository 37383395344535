import { FormikProps } from 'formik'
import * as React from 'react'
import TextInput from '../text-input/text-input'
import './select-input.scss'
import downArrow from '../../assets/down_arrow_blue.svg'
import { ChangeEvent } from 'react'

interface SelectInputProps<T> {
  label: string
  getValue?: (value: string, formikProps: FormikProps<T>) => void
  formikKey: keyof T
  disabled?: boolean
  formikProps: FormikProps<T>
  options: {
    name: string
    id: number
  }[]
}

function SelectInput<T>({ formikProps, label, formikKey, options, disabled, getValue }: SelectInputProps<T>) {
  const handleSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value
    formikProps.setFieldValue(String(formikKey), value)
    if (getValue) {
      getValue(value, formikProps)
    }
  }
  return (
    <div className="SelectInput">
      <div className="SelectInput__inputText">
        <TextInput<T> label={label} formikKey={formikKey} formikProps={formikProps} />
      </div>
      <select
        disabled={disabled}
        value={String(formikProps.values[formikKey])}
        onChange={handleSelect}
        className="SelectInput__options"
        id="selected_bank"
      >
        {Boolean(formikProps.values[formikKey]) ? null : <option value={undefined}>-- Select Option --</option>}
        {options.map(option => (
          <option value={option.name} key={option.id}>
            {option.name}
          </option>
        ))}
      </select>
      <img src={downArrow} />
    </div>
  )
}

export default SelectInput
