import * as React from 'react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { ApiError } from 'ogram-react/api/api-types'
import { createBoundary } from '../../utils/create-boundary'
import './error.scss'
import { Login } from '../../pages/auth/login/login'
import { acceptJobEvents, closeWebView } from '../../utils/webview'
import JobNotFound from '../job-not-found/job-not-found'
import WebViewConnectionError from '../web-view-connection-error/web-view-connection-error'
export const ErrorBoundary = createBoundary(
  props => {
    const tryAgain = () => {
      props.resetError()
    }
    const error = props.error
    if (error !== null) {
      if (error instanceof ApiError) {
        if (error.type === ApiErrorTypes.ServerError) {
          return <ErrorView tryAgain={tryAgain}>Internal Server Error</ErrorView>
        }
        if (error.type === ApiErrorTypes.ConnectionError) {
          // return <ErrorView tryAgain={tryAgain}>Connection Failed</ErrorView>
          return <WebViewConnectionError tryAgain={tryAgain} />
        }
        if (error.type === ApiErrorTypes.NotFound) {
          return <JobNotFound />
        }
        if (error.type === ApiErrorTypes.AuthError) {
          closeWebView(acceptJobEvents.job_application_cancelled)
          return <Login onLogin={tryAgain} />
        }
      }
      return <ErrorView>Something Went Wrong</ErrorView>
    }
    return <>{props.children}</>
  },
  {
    didCatch: (error: Error, errorInfo: React.ErrorInfo) => {
      if (error instanceof ApiError) {
        console.error(error, '\n\n\n', error.data, '\n\n\n', errorInfo.componentStack)
      } else {
        console.error(error, '\n\n\n', errorInfo.componentStack)
      }
    },
  },
)

function ErrorView(props: { children: React.ReactNode; tryAgain?: () => void }) {
  return (
    <div>
      {props.children}
      {props.tryAgain && (
        <div>
          <button onClick={props.tryAgain}>Try Again</button>
        </div>
      )}
    </div>
  )
}
