export enum acceptJobEvents {
  job_application_success = 'job_application_success',
  job_application_cancelled = 'job_application_cancelled',
  job_application_failed = 'job_application_failed',
}

interface Window {
  webkit: { messageHandlers: { nativeapp: { postMessage: (p: string) => void } } }
}

declare const window: Window
declare const Android: { close: () => void; onJobApplication: (eventName: string) => void }

export const closeWebView = (eventName: string) => {
  const event = new Event(eventName)
  document.dispatchEvent(event)

  try {
    //ios - web view
    if (typeof window.webkit !== 'undefined') {
      window.webkit.messageHandlers.nativeapp.postMessage(eventName)
    } else if (typeof Android !== 'undefined' && Android !== null) {
      Android.onJobApplication(eventName)
    }
  } catch {
    // do nothing, probably it happens in webkit in non-native env
  }
}
