import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'

export const NationalityModel = types.model('Nationality', {
  id: types.identifierNumber,
  name: types.string,
})

export const nationalityActions = (self: DataStore) => ({
  getNationalities: flow(function*() {
    const nationalities = (yield self.request('get', `common/nationalities`)) as NationalitySnapshot[]
    nationalities.forEach(nationality => {
      self.nationalities.set(String(nationality.id), nationality)
    })
  }),
})

export const nationalityViews = (self: DataStore) => ({
  getNationalityIdByTitle(title: string) {
    return Array.from(self.nationalities.values()).find(nationality => nationality.name === title)?.id
  },

  getNationalityName(id: number) {
    return Array.from(self.nationalities.values()).find(item => item.id === id)?.name as string
  },

  get nationalityArray() {
    return Array.from(self.nationalities.values())
  },
})

export type Nationality = typeof NationalityModel.Type
export type NationalitySnapshot = typeof NationalityModel.SnapshotType
