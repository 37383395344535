import { cast } from 'assertio'
import * as React from 'react'
import { ErrorData } from 'ogram-react/api/api-types'
import { objectMap } from 'ogram-react/utils/common'
import './form.scss'

export type FormField<T> = {
  name: string
  value: T
  errors: string[]
  setData: (val: T) => void
}
export type FormCtx = {
  data: Record<string, string | undefined>
  error: ErrorData | undefined
  isLoading: boolean
  setData: (key: string, value: string) => void
}

export const formContext = React.createContext<FormCtx>({
  data: {},
  error: {} as FormCtx['error'],
  isLoading: false,
  setData: () => {},
})

export type FormProps<Data> = {
  onSubmit: (data: Data) => void
  data: Data
  setData?: (data: Data) => void
  isLoading: boolean
  error?: FormCtx['error']
}
export function Form<Data>(
  props: FormProps<Data> & {
    children: (data: { [P in keyof Data]: FormField<NonNullable<Data[P]>> }) => React.ReactNode
  },
) {
  const { Provider } = formContext
  const form: FormCtx = {
    data: cast(props.data),
    error: props.error,
    isLoading: props.isLoading,
    setData: (key, value) => {
      props.setData?.({ ...props.data, [key]: value })
    },
  }
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    props.onSubmit(props.data)
  }
  const fields = objectMap<Data, FormField<Data[keyof Data]>>(props.data, (key, value) => ({
    name: String(key),
    value: value,
    errors: props.error?.errors?.[key as string] ?? [],
    setData: newVal => {
      props.setData?.({ ...props.data, [key]: newVal })
    },
  }))
  const commonError = props.error?.code !== 'data_invalid' ? props.error?.message : undefined
  return (
    <Provider value={form}>
      <form onSubmit={handleSubmit}>
        {commonError && (
          <div className="Form__errors">
            <div className="Form__error">{commonError}</div>
          </div>
        )}
        {props.children(cast(fields))}
      </form>
    </Provider>
  )
}
