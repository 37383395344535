import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useMutation, useStore } from '../../models/utils/mst-hooks'
import Header from '../../pages/accept-job/components/header/header'
import UploadDocument from '../upload-document/upload-document'
import './upload-required-documents.scss'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { ChangeEvent, useState } from 'react'
import { useEffect } from 'react'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { observer } from 'mobx-react'
import { DocumentStatus } from '../../../../common/models/documents'
import Stepper from '../../components/stepper/stepper'
import { DocumentModelType } from '../../../../common/models/job'
import UploadConfirmationModal from '../upload-confirmation-modal/upload-confirmation-modal'

const queryString = require('query-string')

export interface HTMLInputEvent extends Event {
  target: HTMLInputElement & EventTarget
}

interface UploadRequiredDocumentsProps {
  onComplete?: () => void
  onUploadDocument: () => void
  onUploadAllDocumnetsComplete: () => void
  requiredDocuments: DocumentModelType[]
  isLoading: boolean
}

const UploadRequiredDocuments = observer(
  ({
    onComplete,
    requiredDocuments,
    onUploadDocument,
    isLoading,
    onUploadAllDocumnetsComplete,
  }: UploadRequiredDocumentsProps) => {
    const { updateDocument } = useStore().data
    const location = useLocation()
    const history = useHistory()
    const documentIndex: number = Number(queryString.parse(location.search).documentIndex) || 0
    const nextDocumentIndex = documentIndex + 1
    const documentsCount = requiredDocuments.length
    const isAnotherDocumentFound = Boolean(nextDocumentIndex < documentsCount)
    const document: DocumentModelType = requiredDocuments[documentIndex]
    const [selectedFile, setSelectedFile] = useState<File | null>(null)
    const [showErrorMesssage, setShowErrorMesssage] = useState(false)
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false)
    const navigateToNextDocument = () => {
      setModalIsOpen(false)
      if (isAnotherDocumentFound) {
        history.push({ pathname: location.pathname, search: `?documentIndex=${nextDocumentIndex}` })
      } else {
        onUploadAllDocumnetsComplete()
      }
    }

    const [uploadDocumentQuery, uploadDocument] = useMutation(updateDocument, {
      filterError: [ApiErrorTypes.ClientError],
      onSuccess: () => {
        onUploadDocument()
        if (!isAnotherDocumentFound) {
          if (onComplete) {
            onComplete()
          }
        }
      },
    })

    useEffect(() => {
      setSelectedFile(null)
    }, [documentIndex])

    const retrieveSelectedFile = ({ target }: ChangeEvent<HTMLInputElement>) => {
      const files = target.files
      if (files) {
        setSelectedFile(files[0])
      }
      setShowErrorMesssage(false)
    }

    const submit = () => {
      if (selectedFile) {
        setModalIsOpen(true)
        uploadDocument({ file: selectedFile, document_type_id: document.type })
      } else if (document.file && document.status !== DocumentStatus.rejected) {
        navigateToNextDocument()
      } else {
        setShowErrorMesssage(true)
      }
    }

    return (
      <div className="UploadRequiredDocuments">
        <Header title="Documents" />
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <>
            <UploadDocument
              hasFile={Boolean(selectedFile)}
              retrieveSelectedFile={retrieveSelectedFile}
              documentData={document}
            />
            {showErrorMesssage && <p className="UploadRequiredDocuments__errorMessage">Please select file to upload</p>}
            <div className="UploadRequiredDocuments__footer">
              <p className="UploadRequiredDocuments__infoMessage">We never share your information with anyone</p>
              <Stepper
                className="UploadRequiredDocuments__stepper"
                stepsCount={documentsCount}
                activeStepIndex={documentIndex}
              />
              <button className="UploadRequiredDocuments__submitButton" onClick={submit}>
                {document.status === DocumentStatus.inReview ? 'Next' : 'Upload'}
              </button>
            </div>
          </>
        )}
        <UploadConfirmationModal
          modalIsOpen={modalIsOpen}
          isLoading={uploadDocumentQuery.isLoading}
          confirm={navigateToNextDocument}
        />
      </div>
    )
  },
)

export default UploadRequiredDocuments
