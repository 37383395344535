import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'
import { FlagsJobModel, LocationModel } from './job'

const BookedShiftsModel = types.model('BookedShifts', {
  id: types.identifierNumber,
  job_id: types.number,
  status_id: types.number,
  sp_active_shift_id: types.number,
  start_time: types.number,
  end_time: types.number,
  time_to_start: types.number,
  time_to_end: types.number,
  status: types.string,
  title: types.string,
  instructions: types.string,
  extention_time: types.maybeNull(types.number),
  currency: types.string,
  price: types.maybeNull(types.number),
  order_name: types.string,
  work_category_name: types.string,
  description: types.string,
  event_description: types.string,
  flags: FlagsJobModel,
  location: LocationModel,
  client_checkin_time: types.maybeNull(types.number),
  client_checkout_time: types.maybeNull(types.number),
  sp_checkin_time: types.maybeNull(types.number),
  sp_checkout_time: types.maybeNull(types.number),
})

export const BookedShiftsStoreModel = types.model('BookedShiftsStore', {
  list: types.map(BookedShiftsModel),
  is_available: types.boolean,
  active_invitations_count: types.number,
  paginator: types.model({
    next_page: types.optional(types.maybeNull(types.number), 1),
  }),
})

export const bookedShiftsActions = (self: DataStore) => ({
  getBookedShifts: flow(function*(firstPage?: boolean) {
    if (!firstPage && !self.bookedShifts?.paginator.next_page) return

    const { list, ...bookedShiftsData } = (yield self.request('post', 'sp/jobs/booked', {
      page: firstPage ? 1 : self.bookedShifts?.paginator.next_page,
    })) as BookedShiftsStoreType

    if (!self.bookedShifts) {
      self.bookedShifts = BookedShiftsStoreModel.create({
        list: {},
        ...bookedShiftsData,
      })
    }

    if (firstPage) {
      self.bookedShifts.list.clear()
    }

    list.forEach((bookedShift: BookedShiftSnapshot) => {
      self.bookedShifts?.list.set(String(bookedShift.id), bookedShift)
    })
    self.bookedShifts.active_invitations_count = bookedShiftsData.active_invitations_count
    self.bookedShifts.is_available = bookedShiftsData.is_available
    self.bookedShifts.paginator.next_page = bookedShiftsData.paginator.next_page
  }),
})

export const bookedShiftsViews = (self: DataStore) => ({
  get bookedShiftsArray() {
    if (!self.bookedShifts?.list) return [] as BookedShiftSnapshot[]
    return Array.from(self.bookedShifts.list.values())
  },

  get bookedShiftsByDate() {
    if (!self.bookedShifts?.list) return []

    type Section = { title: Date; data: BookedShiftSnapshot[] }
    return Array.from(self.bookedShifts.list.values()).reduce<Section[]>((acc, shift) => {
      const existingSection = acc.find(section => {
        const sectionDate = section.title
        const sectionYear = sectionDate.getFullYear()
        const sectionMonth = sectionDate.getMonth()
        const sectionDay = sectionDate.getDate()

        const shiftDate = new Date(shift.start_time * 1000)
        const shiftYear = shiftDate.getFullYear()
        const shiftMonth = shiftDate.getMonth()
        const shiftDay = shiftDate.getDate()
        return sectionDay === shiftDay && sectionMonth === shiftMonth && sectionYear === shiftYear
      })

      if (existingSection) {
        existingSection.data.push(shift)
      } else {
        acc.push({ title: new Date(shift.start_time * 1000), data: [shift] })
      }
      return acc
    }, [])
  },
})

export type BookedShiftsType = Instance<typeof BookedShiftsModel>
export type BookedShiftSnapshot = SnapshotOut<typeof BookedShiftsModel>

export type BookedShiftsStoreType = Instance<typeof BookedShiftsStoreModel>
export type BookedShiftsStoreSnaphot = SnapshotOut<typeof BookedShiftsStoreModel>
