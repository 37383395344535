import * as React from 'react'
import plus from '../../assets/plus-purple.svg'
import check from '../../assets/check-purple.svg'
import infoIconOrange from '../../assets/info-icon-orange.svg'
import infoIconRed from '../../assets/info-icon-red.svg'
import file from '../../assets/file-purple.svg'
import './upload-document.scss'
import { DocumentStatus } from '../../../../common/models/documents'
import { DocumentModelType } from '../../../../common/models/job'
import { ChangeEvent } from 'react'

interface UploadDocumentProps {
  documentData: DocumentModelType
  retrieveSelectedFile: (e: ChangeEvent<HTMLInputElement>) => void
  hasFile: boolean
}

const COMPLIANCE_TEXT = 'We comply with the UAE laws. You need to upload allrequired documents to get approved.'

const UploadDocument = ({ documentData, retrieveSelectedFile, hasFile }: UploadDocumentProps) => {
  const isDocumentInReview = documentData.status === DocumentStatus.inReview
  const isDocumentRejected = documentData.status === DocumentStatus.rejected

  return (
    <div className="UploadDocument">
      <h3 className="UploadDocument__title">Upload {documentData.display_name}</h3>

      <div className="UploadDocument__uploadContainer">
        <div className="UploadDocument__hint">
          <img className="UploadDocument__infoIcon" src={isDocumentRejected ? infoIconRed : infoIconOrange} />
          <h6>{isDocumentRejected ? 'Document Rejected' : isDocumentInReview ? 'Pending Approval' : 'Compliance'}</h6>
          <p>{isDocumentRejected ? documentData.note : isDocumentInReview ? '' : COMPLIANCE_TEXT}</p>
        </div>

        <p className="UploadDocument__label">{documentData.display_name}</p>
        <input
          disabled={isDocumentInReview}
          onChange={retrieveSelectedFile}
          value={''}
          id="upload"
          type="file"
          className="UploadDocument__uploadInput"
        />
        <label htmlFor="upload" className="UploadDocument__upload">
          <img src={hasFile ? file : isDocumentInReview ? check : plus} />
          {isDocumentInReview ? (
            <p className="UploadDocument__uploadText">{'Document Submitted'}</p>
          ) : hasFile ? (
            <p className="UploadDocument__uploadText">Replace</p>
          ) : null}
        </label>
      </div>
    </div>
  )
}

export default UploadDocument
