import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import './payout-iban.scss'
import PayoutFooter from '../payout-footer/payout-footer'
import TextInput from '../text-input/text-input'
import { Formik, Form, FormikProps, FormikErrors } from 'formik'
import * as Yup from 'yup'
import { useMutation, useStore } from '../../models/utils/mst-hooks'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'

export interface IbanForm {
  account_number: string | undefined
}

interface PayoutIbanInterface {
  onComplete: () => void
}

let formikRef: FormikProps<IbanForm> | null

const PayoutIban = observer(({ onComplete }: PayoutIbanInterface) => {
  const { payout, setPayoutData, validatePayoutData } = useStore().data
  const [validatePayoutResult, validate] = useMutation(validatePayoutData, {
    onSuccess: () => {
      onComplete()
    },
    onError: error => {
      const { errors } = error.data
      formikRef?.setErrors(errors as FormikErrors<IbanForm>)
    },
    filterError: [ApiErrorTypes.ClientError],
  })

  return (
    <div className="PayoutIban">
      <Header title="Payout" />
      <h2 className="PayoutIban__title">Submit IBAN number</h2>

      {validatePayoutResult.isLoading ? <LoadingSpinner /> : null}
      <Formik
        innerRef={ref => {
          formikRef = ref
        }}
        initialValues={{
          account_number: payout?.account_number,
        }}
        onSubmit={values => {
          validate({ ...values, type: payout?.type })
          setPayoutData(values)
        }}
        validationSchema={Yup.object().shape({
          account_number: Yup.string().required('This field is required'),
        })}
      >
        {(formikProps: FormikProps<IbanForm>) => (
          <Form
            className={validatePayoutResult.isLoading ? 'PayoutIban__formBody hide' : 'PayoutIban__formBody'}
            noValidate
          >
            <TextInput label="IBAN" formikKey="account_number" formikProps={formikProps} />
            <div className="footer-container">
              <PayoutFooter activeStepIndex={2} />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
})

export default PayoutIban
