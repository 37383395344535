import * as React from 'react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import PayoutPaymaxOptions from '../../../components/payout-paymax-options/payout-paymax-options'
import { routes } from '../../../routes'

const AcceptJobPayoutPaymaxOptions = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const onComplete = () => {
    const nextUrl = generatePath(routes.acceptJobPayoutPayMaxTerms, { id })
    history.push(nextUrl)
  }

  return <PayoutPaymaxOptions onComplete={onComplete} />
}

export default AcceptJobPayoutPaymaxOptions
