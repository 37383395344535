import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const EducationModel = types.model('Education', {
  id: types.identifierNumber,
  school: types.string,
  degree: types.string,
  study: types.string,
})

export const educationActions = (self: DataStore) => ({
  getEducationList: flow(function*() {
    const educations: EducationSnapshot[] = yield self.request('get', 'sp/profile/education/list')
    self.education.clear()
    educations.forEach(education => {
      self.education.set(String(education.id), education)
    })
  }),

  createEducation: flow(function*(educationForm: EducationForm) {
    yield self.request('post', 'sp/profile/education/create', educationForm)
  }),

  updateEducation: flow(function*(educationForm: EducationForm & { id: number }) {
    yield self.request('post', 'sp/profile/education/update', educationForm)
  }),

  deleteEducation: flow(function*(id: number) {
    yield self.request('post', 'sp/profile/education/delete', { id })
  }),
})

export const educationViews = (self: DataStore) => ({
  get educationList() {
    return Array.from(self.education.values())
  },

  getEducationById(id: number) {
    return self.education.get(String(id))
  },
})

export type Education = Instance<typeof EducationModel>
export type EducationSnapshot = SnapshotOut<typeof EducationModel>

export type EducationForm = {
  school: string
  degree: string
  study: string
}
