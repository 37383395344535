export const routes = {
  login: '/',
  editProfile: '/profile/edit',
  acceptJobProgress: '/job/:id/accept/progress',
  acceptJobQuiz: '/job/:id/accept/quiz',
  acceptJobRequirementsAndPolicies: '/job/:id/accept/requirements-and-policies',
  acceptJobShifts: '/job/:id/accept/shifts',
  acceptJobConfirmation: '/job/:id/accept/confirm',
  acceptJobFullfilled: '/job/:id/accept/job-already-taken',
  acceptJobPayoutChoosePayment: '/job/:id/accept/choose-payment-method',
  acceptJobPayoutChooseBank: '/job/:id/accept/choose-bank',
  acceptJobPayoutIBAN: '/job/:id/accept/iban',
  acceptJobPayoutName: '/job/:id/accept/payout-name',
  acceptJobPayoutTerms: '/job/:id/accept/payout-terms',
  acceptJobPayoutChoosePayMaxCard: '/job/:id/accept/paymax',
  acceptJobPayoutPayMaxTerms: '/job/:id/accept/paymax-terms',
  acceptJobPayoutPayMaxTiming: '/job/:id/accept/paymax-timing',
  acceptJobPayoutPayMaxLocation: '/job/:id/accept/paymax-location',
  acceptJobRequirementsNotAccepted: '/job/accept/requirements-notAccepted',
  acceptJobDocuments: '/job/:id/accept/requiredDocs',
}
