import * as React from 'react'
import './stepper.scss'

interface StepperProps {
  stepsCount: number
  activeStepIndex: number
  className?: string
}

const Stepper = ({ stepsCount, activeStepIndex, className }: StepperProps) => {
  const renderSteps = () => {
    let i = 0
    const steps = []
    while (stepsCount > i) {
      steps.push(<div key={i} className={activeStepIndex === i ? 'Stepper__step active' : 'Stepper__step'}></div>)
      i++
    }
    return steps
  }
  return <div className={`Stepper ${className}`}>{renderSteps()}</div>
}

export default Stepper
