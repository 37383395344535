import { toJS } from 'mobx'
import { types, flow, Instance, SnapshotOut } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export enum DocumentStatus {
  inReview = 1,
  approved = 2,
  rejected = 3,
}

export enum DocumentType {
  visa = 1,
  passport = 2,
  idFront = 4,
  idBack = 5,
  resume = 6,
  photoFace = 7,
  photoBody = 8,
  certificateHealth = 10,
  certificateFood = 11,
  certificateSecurityId = 12,
}

export type File = {
  name: string
  type: string
  uri?: string
}

const DocumentModel = types.model('Document', {
  id: types.identifierNumber,
  file: types.maybeNull(types.string),
  note: types.maybeNull(types.string),
  issue_date: types.maybeNull(types.string),
  expiry_date: types.maybeNull(types.string),
  status: types.maybeNull(types.number),
  document_identifier: types.maybeNull(types.string),
})

export const DocumentFieldModel = types.model('DocumentField', {
  id: types.identifierNumber,
  name: types.string,
  display_name: types.string,
  is_required: types.number,
  icon: types.string,
  document: types.maybeNull(DocumentModel),
})

export const documentActions = (self: DataStore) => ({
  getDocumentsList: flow(function*() {
    const documents = (yield self.request('post', `sp/profile/document`)) as DocumentFieldSnapshot[]
    documents.forEach(document => {
      self.documents.set(String(document.id), document)
    })
  }),

  updateDocument: flow(function*(
    document: { file: File; document_type_id: number },
    onUploadProgress?: ({ loaded, total }: { loaded: number; total: number }) => void,
  ) {
    yield self.request('post', `sp/profile/document/upload`, document, {
      headers: { 'Content-Type': 'multipart/form-data' },
      onUploadProgress,
    })
    const documentField = self.documents.get(String(document.document_type_id))
    if (documentField) {
      documentField.document = DocumentModel.create({
        id: document.document_type_id,
        status: DocumentStatus.inReview,
      })
    }
  }),
})

export const documentViews = (self: DataStore) => ({
  get documentList() {
    return Array.from(self.documents.values())
  },

  get optionalDocumentList() {
    return Array.from(self.documents.values()).filter(doc => doc.is_required === 0)
  },

  get requiredDocumentList() {
    return Array.from(self.documents.values()).filter(doc => doc.is_required === 1)
  },

  get areOptionalDocumentsFulfilled() {
    const docsArray = Array.from(self.documents.values())
    const optionalNotFulfilledDocument = docsArray.find(doc => {
      const docStatus = doc.document?.status
      const isOptional = doc.is_required === 0
      const isFulfiled = docStatus === DocumentStatus.approved || docStatus === DocumentStatus.inReview
      return isOptional && !isFulfiled
    })

    return optionalNotFulfilledDocument === undefined
  },

  getDocument(id: number) {
    return toJS(self.documents.get(String(id)))
  },
})

export type DocumentField = Instance<typeof DocumentFieldModel>
export type DocumentFieldSnapshot = SnapshotOut<typeof DocumentFieldModel>

export type Document = Instance<typeof DocumentModel>
export type DocumentSnapshot = SnapshotOut<typeof DocumentModel>
