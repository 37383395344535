import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import * as React from 'react'
import { useState } from 'react'
import { PaymaxPickUpLocation, PaymentMethodIds, PayoutPostRequestData } from '../../../../common/models/payout'
import { useMutation, useStore } from '../../models/utils/mst-hooks'
import Header from '../../pages/accept-job/components/header/header'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import PayoutFooter from '../payout-footer/payout-footer'
import './payout-terms.scss'

interface PayoutTermsProps {
  onComplete: () => void
}

const bankAccountTerms =
  '1. I give full authorization for Ogram FZ LLC to transfer my amount due to the submitted bank account in my name.<br/><br/>2. I acknowledge that I can use the submitted bank account details to withdraw my entire payout.<br/><br/>3. I bear all responsibility for having access to my bank account and my bank cards.'
const existedPaymaxCardTerms =
  '1. I give full authorization for Ogram FZ LLC to transfer my amount due to the submitted Pay Max card in my name.<br/><br/>2. I acknowledge that I can use the Pay Max card to withdraw my entire payout. <br/><br/>3. I bear all responsibility for safekeeping the physical Pay Max card.'
const newPaymaxCardTerms =
  '1. I give full authorization for Ogram FZ LLC to request a new Pay Max card in my name. <br /><br />2. I acknowledge that I can use the Pay Max card to withdraw my entire payout.<br /><br />3. I bear all responsibility for collecting and safekeeping the physical Pay Max card.'

const PayoutTerms = observer(({ onComplete }: PayoutTermsProps) => {
  const { updatePayoutMethod, payout, requestPaymaxCard } = useStore().data
  const [errorMessages, setErrorMessages] = useState<string[]>([])
  const [handleSubmitResult, handleSubmit] = useMutation(
    (payoutData: PayoutPostRequestData | PaymaxPickUpLocation) => {
      if (payout?.type === '1') {
        return updatePayoutMethod(payoutData as PayoutPostRequestData)
      } else {
        return requestPaymaxCard(payoutData as PaymaxPickUpLocation)
      }
    },
    {
      onSuccess: onComplete,
      onError: error => {
        const { errors } = error.data
        if (errors) {
          for (const property in errors) {
            if (errors.hasOwnProperty(property)) {
              const errorTextMessages: string[] = []
              errors[property].forEach(errorText => errorTextMessages.push(errorText))
              setErrorMessages(errorTextMessages)
            }
          }
        } else {
          setErrorMessages([error.data.message])
        }
      },
      filterError: [ApiErrorTypes.ClientError],
    },
  )

  const switchTerms = (paymentType: string | undefined) => {
    switch (paymentType) {
      case String(1):
        return bankAccountTerms
      case String(2):
        return existedPaymaxCardTerms
      default:
        return newPaymaxCardTerms
    }
  }

  const submitPayoutData = () => {
    if (payout?.type === '1') {
      const payoutData: PayoutPostRequestData = {
        type: Number(payout.type) as PaymentMethodIds,
        bank_id: Number(payout.selected_bank),
        name: payout.name,
        account_number: payout.account_number,
        address: payout.address,
        country: payout.country,
      }
      if (Number(payout.type) === PaymentMethodIds.paymax) {
        delete payoutData.bank_id
      }
      handleSubmit(payoutData)
    } else {
      handleSubmit({ branch_id: Number(payout?.branch_id) })
    }
  }

  return (
    <div className="PayoutTerms">
      {!handleSubmitResult.isLoading ? (
        <>
          <Header title="Payout" />
          <h2 className="PayoutTerms__title">Do you accept the additional terms?</h2>
          <div className="PayoutTerms__bodyContainer">
            <div className="PayoutTerms__termsTextContainer custom-scrollbar">
              <p dangerouslySetInnerHTML={{ __html: switchTerms(payout?.type) }}></p>
            </div>
            {errorMessages.map((error, i) => (
              <p key={i} className="PayoutTerms__errorMessage">
                {error}
              </p>
            ))}
            <PayoutFooter activeStepIndex={4} handleSubmit={submitPayoutData} />
          </div>
        </>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
})

export default PayoutTerms
