import * as React from 'react'
import { acceptJobEvents, closeWebView } from '../../utils/webview'
import './web-view-connection-error.scss'
interface WebViewConnectionErrorProps {
  tryAgain: () => void
}

const WebViewConnectionError = ({ tryAgain }: WebViewConnectionErrorProps) => {
  return (
    <div className="WebViewConnectionError">
      <p className="WebViewConnectionError__text">Network Connection Error</p>
      <button onClick={tryAgain} className="WebViewConnectionError__tryAgainButton">
        Try Again
      </button>
      <button
        onClick={() => closeWebView(acceptJobEvents.job_application_failed)}
        className="WebViewConnectionError__exploreButton"
      >
        Explore jobs
      </button>
    </div>
  )
}

export default WebViewConnectionError
