import { observer } from 'mobx-react'
import * as React from 'react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { Form } from '../../../components/form/form'
import { InputField } from '../../../components/form/input/input'
import { Submit } from '../../../components/form/submit/submit'
import { useMutation, useStore } from '../../../models/utils/mst-hooks'
import { useFormState } from '../../../utils/form-state'
import './login.scss'

export const Login = observer((props: { onLogin?: () => void }) => {
  const store = useStore()
  const [data, setData] = useFormState({ email: '', password: '' })
  const [loginResult, login] = useMutation(
    () => store.data.login({ email: data.email, password: data.password, device_id: '11', firebase_token: '1111' }),
    {
      onSuccess: () => {
        store.data.loginLocal()
        props.onLogin?.()
      },
      filterError: [ApiErrorTypes.ClientError],
    },
  )
  return (
    <div className="Login">
      <h1>Login</h1>
      <Form
        data={data}
        setData={setData}
        error={loginResult.error?.data}
        isLoading={loginResult.isLoading}
        onSubmit={login}
      >
        {() => (
          <>
            <InputField label="Email" type="email" value={data.email} onChange={val => setData({ email: val })} />
            <InputField
              label="Password"
              type="password"
              value={data.password}
              onChange={val => setData({ password: val })}
            />
            <Submit>Login</Submit>
          </>
        )}
      </Form>
    </div>
  )
})
