import * as React from 'react'
import './quiz-overview.scss'
import { useParams, Link, generatePath } from 'react-router-dom'
import { routes } from '../../routes'
import Header from '../../pages/accept-job/components/header/header'
import { useQuery, useStore } from '../../models/utils/mst-hooks'
import { useEffect } from 'react'
import { hideYoutubeRelatedVideos } from '../../utils/youtube'

const QuizOverview = (props: {}) => {
  const { id } = useParams<{ id: string }>()
  const { data } = useStore()
  useQuery(data.getQuiz)
  const { quiz } = data

  useEffect(() => {
    hideYoutubeRelatedVideos('JobOverview__videoPlayer')
  }, [])

  return (
    <div className="JobOverview">
      <Header title="Overview" />
      <div className="JobOverview__videoPlayer">
        <iframe
          width="100%"
          height="234"
          src={quiz.mediaSource}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <div className="JobOverview__overviewDescription custom-scrollbar">
        <h2 className="JobOverview__title">{quiz.header}</h2>
        <p className="JobOverview__description" dangerouslySetInnerHTML={{ __html: quiz.content }} />
      </div>
      <Link
        to={{
          pathname: generatePath(routes.acceptJobQuiz, {
            id,
          }),
          search: '?questionIndex=0',
        }}
        className="JobOverview__startQuizButton"
      >
        Start Quiz
      </Link>
    </div>
  )
}

export default QuizOverview
