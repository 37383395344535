import * as React from 'react'
import { useParams, generatePath } from 'react-router-dom'
import PayoutPaymaxLocation from '../../../components/payout-paymax-location/payout-paymax-location'
import { routes } from '../../../routes'
import { useHistory } from 'react-router-dom'

const AcceptJobPayoutPaymaxLocation = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const onComplete = () => history.push(generatePath(routes.acceptJobPayoutTerms, { id }))

  return <PayoutPaymaxLocation onComplete={onComplete} />
}

export default AcceptJobPayoutPaymaxLocation
