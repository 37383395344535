import * as React from 'react'
import { useStore } from '../../../models/utils/mst-hooks'
import { closeWebView, acceptJobEvents } from '../../../utils/webview'
import close from '../assets/close.svg'
import './accept-job-requirements-not-accepted.scss'
import { useHistory } from 'react-router-dom'

const AcceptJobRequirementsNotAccepted = () => {
  const { getAnswers } = useStore().data
  const history = useHistory()
  const closeWebview = () => closeWebView(acceptJobEvents.job_application_cancelled)
  const goBack = () => history.goBack()

  return (
    <div className="AcceptJobRequirementsNotAccepted">
      <div className="AcceptJobRequirementsNotAccepted__overviewClose">
        <img onClick={closeWebview} src={close} alt="close or cancel accept job" />
      </div>
      <div className="AcceptJobRequirementsNotAccepted__body">
        <h2 className="AcceptJobRequirementsNotAccepted__title">
          Oops! You didn’t meet the following requirements for this job:
        </h2>
        <div className="AcceptJobRequirementsNotAccepted__requirements">
          {getAnswers().map(answer => (answer.answer === 'no' ? <p key={answer.id}>{answer.requirement}</p> : null))}
        </div>
        <div className="AcceptJobRequirementsNotAccepted__buttonsContainer">
          <button className="AcceptJobRequirementsNotAccepted__close" onClick={closeWebview}>
            Find another job
          </button>
          <button className="AcceptJobRequirementsNotAccepted__back" onClick={goBack}>
            Go back and edit
          </button>
        </div>
      </div>
    </div>
  )
}

export default AcceptJobRequirementsNotAccepted
