import { types, flow } from 'mobx-state-tree'

import { DataStore } from './data-store/data-store'
import { Paginator } from 'ogram-react/api/api-types'

const NotificationDataModel = types.model('NotificationData', {
  shift_id: types.maybe(types.number),
  sp_id: types.maybe(types.number),
  job_id: types.maybe(types.number),
  order_id: types.maybe(types.number),
  channelId: types.maybe(types.string),
  invitation_id: types.maybe(types.number),
  document_type_id: types.maybe(types.number),
  confirmation_id: types.maybe(types.number),
  header: types.maybe(types.string),
  link: types.maybeNull(types.string),
  link_title: types.maybe(types.string),
  body: types.maybe(types.string),
})

export type NotificationData = typeof NotificationDataModel.Type
export type NotificationDataSnapshot = typeof NotificationDataModel.SnapshotType

const NotificationModel = types.model('Notification', {
  id: types.identifier,
  message: types.string,
  data: NotificationDataModel,
  is_read: types.optional(types.boolean, false),
  popup: types.optional(types.number, 0),
  popup_image: types.optional(types.string, ''),
  subject: types.string,
  url: types.maybeNull(types.string),
  date: types.number,
  entity_id: types.number,
})

export type Notification = typeof NotificationModel.Type
export type NotificationSnapshot = typeof NotificationModel.SnapshotType

export const NotificationStoreModel = types.model('NotificationStore', {
  list: types.map(NotificationModel),
  unreadCount: types.optional(types.number, 0),
  nextPage: types.optional(types.maybeNull(types.number), 1),
})

export const notificationActions = (self: DataStore) => {
  const actions = {
    fetchNotifications: flow(function*(firstPage?: boolean) {
      if (!firstPage && !self.notification.nextPage) return

      const { list: notifications = [], paginator } = (yield self.request('post', 'sp/notifications/list', {
        page: firstPage ? 1 : self.notification.nextPage,
      })) as { list: NotificationSnapshot[]; paginator?: Paginator }

      if (firstPage) {
        self.notification.list.clear()
      }

      notifications.forEach((notification: NotificationSnapshot) => {
        self.notification.list.set(String(notification.id), notification)
      })
      if (paginator) {
        self.notification.nextPage = paginator.next_page
      }
    }),

    readNotification: flow(function*(id: string) {
      yield self.request('post', 'sp/notifications/mark-as-read', { id })
      const notification = self.notification.list.get(id)
      if (notification) {
        notification.is_read = true
      }
    }),

    getUnreadNotificationsCount: flow(function*() {
      self.notification.unreadCount = (yield self.request('post', 'sp/notifications/new')).count
    }),

    incrementUnreadNotifications: function() {
      self.notification.unreadCount++
    },

    decrementUnreadNotifications: function() {
      if (self.notification.unreadCount > 0) {
        self.notification.unreadCount--
      }
    },

    resetUnreadNotificationsCount: function(count = 0) {
      self.notification.unreadCount = count
    },
  }
  return actions
}

export const notificationViews = (self: DataStore) => ({
  get notifications() {
    return Array.from(self.notification.list.values())
  },
})

export type NotificationStore = typeof NotificationStoreModel.Type
export type NotificationStoreSnapshot = typeof NotificationStoreModel.SnapshotType

export enum NotificationEntityType {
  jobDetails = 1,
  documentation = 4,
  availability = 6,
  invitation = 7,
  opportunity = 14,
  alert = 15,
  payoutDetails = 17,
}
