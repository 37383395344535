import * as React from 'react'
import close from '../assets/close.svg'
import './accept-job-shifts.scss'
import calender from '../assets/calender.svg'
import clock from '../assets/clock-blue.svg'
import { useMutation, useQuery, useStore } from '../../../models/utils/mst-hooks'
import { observer } from 'mobx-react'
import { generatePath, useHistory, useParams } from 'react-router-dom'
import { FormatedJobErrror, formatJobDetailsError } from '../../../utils/job-utils'
import { routes } from '../../../routes'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { ShiftModelType } from '../../../../../common/models/job'
import { useState } from 'react'
import AcceptJobAlreadyFullfilled from '../../../components/job-fullfilled/job-fullfilled'
import { convertMilisecondsClock, formatDate } from '../../../utils/date-utils'
import { closeWebView, acceptJobEvents } from '../../../utils/webview'
import LoadingSpinner from '../../../components/loading-spinner/loading-spinner'

const AcceptJobShifts = observer(() => {
  const { id } = useParams<{ id: string }>()
  const { getJob, getShiftsList, saveJob, job, acceptInvitation } = useStore().data
  const [jobDetailsError, setJobDetailsError] = useState<FormatedJobErrror>()
  const [showJobFulfilledError, setShowJobFulfilledError] = useState(false)
  const [saveJobQuery, submitSaveJob] = useMutation(
    (data: { confirmed: number; jobId: number }) => {
      if (job?.flags.showInvite) {
        return acceptInvitation(data)
      }
      return saveJob(data)
    },
    {
      onSuccess: () => {
        history.push(generatePath(routes.acceptJobConfirmation, { id }))
      },
      filterError: [ApiErrorTypes.ClientError],
      onError: err => {
        if (err.type === ApiErrorTypes.NotFound) {
          if (err.data.errors) {
            setJobDetailsError(formatJobDetailsError(err.data.errors))
          }
          setShowJobFulfilledError(true)
        } else {
          alert(err.data.message)
          closeWebView(acceptJobEvents.job_application_failed)
        }
      },
    },
  )
  const history = useHistory()
  const jobQuery = useQuery(() => getJob(Number(id)), {
    filterError: [ApiErrorTypes.ClientError],
    onError: err => {
      if (err.data.errors) {
        setJobDetailsError(formatJobDetailsError(err.data.errors))
      }
      setShowJobFulfilledError(true)
    },
  })
  const shifts = job
    ? getShiftsList()
        .sort((shift, nextShift) => shift.start_time - nextShift.start_time)
        .map((shift: ShiftModelType) => ({
          ...shift,
          start_time: convertMilisecondsClock(shift.start_time * 1000),
          end_time: convertMilisecondsClock(shift.end_time * 1000),
          startDate: formatDate(shift.start_time * 1000),
          hours: Math.floor(shift.hours),
        }))
    : []

  return (
    <>
      {!jobQuery.isLoading ? (
        showJobFulfilledError ? (
          <AcceptJobAlreadyFullfilled jobDetailsError={jobDetailsError} />
        ) : (
          <div className="Shifts">
            <>
              <div className="Shifts__close">
                <img
                  onClick={() => closeWebView(acceptJobEvents.job_application_cancelled)}
                  src={close}
                  alt="close or cancel accept job"
                />
              </div>
              <div className="Shifts__tableContainer">
                <h3 className="Shifts__desc">
                  By accepting this job, you are committing to work at {job?.location?.name} on:
                </h3>
                <table className="Shifts__table">
                  <thead>
                    <tr className="Shifts__tableHeader">
                      <th>
                        <div className="Shifts__tableHeaderCell Shifts__tableHeaderCell--leftAlign">
                          <img src={calender} />
                          <span>Dates</span>
                        </div>
                      </th>
                      <th>
                        <div className="Shifts__tableHeaderCell Shifts__tableHeaderCell--centerAlign">
                          <img src={clock} />
                          <span>Timing</span>
                        </div>
                      </th>
                      <th>
                        <div className="Shifts__tableHeaderCell Shifts__tableHeaderCell--rightAlign">
                          <img src={clock} />
                          <span>Hours</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {shifts.map(
                      (shift: {
                        id: string | number | null | undefined
                        startDate: { day: React.ReactNode; date: React.ReactNode }
                        start_time: React.ReactNode
                        end_time: React.ReactNode
                        hours: React.ReactNode
                      }) => (
                        <tr key={shift.id}>
                          <td className="Shifts__date">
                            <div>
                              {shift.startDate.day}
                              <br /> {shift.startDate.date}
                            </div>
                          </td>
                          <td className="Shifts__time">
                            <span>{shift.start_time}</span>
                            <br />
                            <span>{shift.end_time}</span>
                          </td>
                          <td className="Shifts__hours">
                            <span>{shift.hours}</span>
                          </td>
                        </tr>
                      ),
                    )}
                  </tbody>
                </table>
              </div>
            </>
            <button
              disabled={saveJobQuery.isLoading}
              className="Shifts_nextButton"
              onClick={() => {
                submitSaveJob({ jobId: Number(id), confirmed: 1 })
              }}
            >
              Yes, I’ll be there
            </button>
          </div>
        )
      ) : (
        <LoadingSpinner />
      )}
    </>
  )
})

export default AcceptJobShifts
