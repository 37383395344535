import * as React from 'react'
import { Field, FormikProps } from 'formik'
import './text-input.scss'

interface TextInputProps<T> {
  label: string
  getValue?: (value: string) => void
  formikKey: keyof T
  type?: string
  disabled?: boolean
  formikProps: FormikProps<T>
}

function TextInput<T>({ label, getValue, formikKey, formikProps, type, disabled }: TextInputProps<T>) {
  const handleKeyUp = (event: Event) => {
    if (getValue) {
      getValue((event.target as HTMLTextAreaElement).value)
    }
  }
  return (
    <div className="TextInput">
      <Field
        disabled={disabled}
        name={formikKey}
        autoComplete="off"
        className="TextInput__input"
        onKeyUp={handleKeyUp}
        type={type ?? 'text'}
        required
      />
      <label className="input-label">{label}</label>
      <p className="error-msg">{formikProps.touched[formikKey] && formikProps.errors[formikKey]}</p>
    </div>
  )
}

export default TextInput
