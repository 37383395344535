import * as React from 'react'
import { useStore } from '../../../models/utils/mst-hooks'
import { closeWebView, acceptJobEvents } from '../../../utils/webview'
import checkmark from '../assets/bigCheck.svg'
import './accept-job-confirmation.scss'
import { useState } from 'react'
import { useEffect } from 'react'

interface data {
  title: string
  subtitle: string
  steps: string[]
  submitText: string
}

const AcceptJobConfirmation = (props: {}) => {
  const { job } = useStore().data
  const [data, setData] = useState<data>({
    title: 'Job Confirmed',
    subtitle: 'Here are a few tips for you:',
    steps: [
      'Find out how you can get to the location before starting the shift.',
      'Make sure you have read the uniform requirements.',
    ],
    submitText: `Let's go`,
  })
  useEffect(() => {
    if (job?.flags.showApply) {
      setData({
        title: 'Job Applied',
        subtitle: 'What will happen next:',
        steps: ['Client will review your profile.', 'You will receive a notification once selected.'],
        submitText: 'Got it'
      })
    }
  }, [job])

  return (
    <div className="Confirmation">
      <div className="Confirmation__pageBody">
        <h2 className="Confirmation__title">{data.title}</h2>
        <img className="Confirmation__checkmark" src={checkmark} alt="job confirmed" />
        <h5 className="Confirmation__subTitle">{data.subtitle}</h5>
        <ul className="Confirmation__list">
          {data.steps.map((step, i: number) => (
            <li key={i}>
              <span>{i + 1}</span>
              <p>{step}</p>
            </li>
          ))}
        </ul>
      </div>
      <button
        className="Confirmation__nextButton"
        onClick={() => closeWebView(acceptJobEvents.job_application_success)}
      >
        {data.submitText}
      </button>
    </div>
  )
}

export default AcceptJobConfirmation
