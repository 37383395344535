import { flow, types } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'
import { values } from 'mobx'
import { cast } from 'mobx-state-tree'

const AnswerModel = types.model('Answer', {
  text: types.string,
  hint: types.string,
  isCorrect: types.boolean,
})

export const QuestionModel = types.model('Question', {
  id: types.string,
  question: types.string,
  answers: types.array(AnswerModel),
})

export const AnsweredQuestionModel = types.model('AnsweredQuestion', {
  questionId: types.string,
  answerIndex: types.number,
})

export const QuizModel = types.model('Quiz', {
  header: types.optional(types.string, ''),
  mediaSource: types.optional(types.string, ''),
  content: types.optional(types.string, ''),
  questions: types.optional(types.array(QuestionModel), []),
  answeredQuestions: types.optional(types.array(AnsweredQuestionModel), []),
})

export const quizActions = (self: DataStore) => {
  return {
    getQuiz: flow(function*() {
      self.quiz = cast({
        'header': 'What is Ogram?',
        'mediaSource': 'https://www.youtube.com/embed/v90cnG4QhFw?rel=0&enablejsapi=1',
        'content': `<p>At Ogram, we enable you to take part-time jobs with Employers. Join us in revolutionizing the future of the workforce.
        </p>
        <p>Once you accept a job, make sure you are 100% able to attend the job. By accepting a job, you are automatically bound by the hourly rate and requirements listed.</p>`,
        'questions': [
          {
            'id': 'inapp_action',
            'question': 'When working a job, what are the required (in-app) actions you must take?',
            'answers': [
              {
                'text': 'A) Just show up.',
                'hint':
                  'Showing up is important, but you also need to check in and out with the app. Please try again.',
                'isCorrect': false,
              },
              {
                'text': 'B) Do nothing. Say nothing.',
                'hint': 'Doing nothing, will get you nothing :) Please try again.',
                'isCorrect': false,
              },
              {
                'text': "C) 'Accept' the job, work and then go to pick up your paycheck.",
                'hint': "Ogram payments are electronic. It won't help to visit the office. Please try again.",
                'isCorrect': false,
              },
              {
                'text': "D) 'Accept' the job, 'Check-In' the job and 'Check-Out' when finished.",
                'hint':
                  "Your app is your go-to for managing your accepted jobs. It's also your check-in, check-out system when you're working.",
                'isCorrect': true,
              },
            ],
          },
          {
            'id': 'get_paid',
            'question': 'How do you get paid for jobs worked on Ogram?',
            'answers': [
              {
                'text': ' A) The employer will pay cash after each job is complete.﻿',
                'hint':
                  "Employers won't pay you directly. You will always receive your payment via the Ogram App. Please try again.",
                'isCorrect': false,
              },
              {
                'text': ' B) You will get a check delivered by courier service.',
                'hint':
                  'You are paid through Bank transfer or PayMax card. Set this up on your profile. Please try again.',
                'isCorrect': false,
              },
              {
                'text': ' C) You will get paid through Paypal.',
                'hint': 'Paypal is great, but we only process your payments via the Ogram app. Please try again.',
                'isCorrect': false,
              },
              {
                'text': ' D) In order to get paid, you must input your Bank details or get a Pay Max card.',
                'hint': 'Once the Job is complete, it can take up to 45 days before you receive the amount.',
                'isCorrect': true,
              },
            ],
          },
          {
            'id': 'get_banned',
            'question': 'Which of these actions will result in you being banned from Ogram?',
            'answers': [
              {
                'text': 'A) Calling up the employer directly to ask for a job﻿.﻿',
                'hint':
                  "Employers won't hire you directly. There is a reason for why they use Ogram to hire part-timers. What else? Please try again.",
                'isCorrect': false,
              },
              {
                'text': 'B) Not showing up to a job that you have accepted without notifying Ogram.',
                'hint':
                  'True. We rely on you to show up for the job. Repeat no shows will get your profile deactivated. What else? Please try again.',
                'isCorrect': false,
              },
              {
                'text': 'C) Breach of Ogram code of conduct with bad behavior at work﻿.',
                'hint':
                  'True. Bad behavior is not accepted by Ogram. Read our code of conduct document. What else? Please try again.',
                'isCorrect': false,
              },
              {
                'text': 'D) All of the above',
                'hint':
                  'We will always trust you to schedule your time wisely, just keep us in the loop when you have an emergency!',
                'isCorrect': true,
              },
            ],
          },
        ],
        answeredQuestions: self.quiz.answeredQuestions,
      })
    }),
    saveQuizAnswer: function(questionId: string, answerIndex: number) {
      return self.quiz.answeredQuestions.push({ questionId, answerIndex })
    },
  }
}

export const quizViews = (self: DataStore) => ({
  getAnsweredQuestions() {
    return values(self.quiz.answeredQuestions)
  },
})

export type QuizDataType = typeof QuizModel.Type
export type QuestionDataType = typeof QuestionModel.Type
export type AnsweredQuestionsType = typeof AnsweredQuestionModel.Type
