import { flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export enum VersionStatus {
  updateRequired = 2,
  updateOptional = 1,
  noUpdate = 0,
}

export const commonActions = (self: DataStore) => ({
  sendFeedback: flow(function*(text: string) {
    yield self.request('post', 'common/feedback', { text })
  }),

  checkVersion: flow(function*({ platform, version }: { platform: number; version: string }) {
    return (yield self.request('post', 'common/version/check', {
      user_platform: platform,
      version,
    })) as { status: VersionStatus; min: string; latest: string }
  }),

  getRejectionReasons: flow(function*() {
    return (yield self.request('get', 'common/invitation-reject-reasons')) as { id: number; name: string }[]
  }),

  getShiftCancelReasons: flow(function*() {
    return (yield self.request('get', 'common/shift-cancel-reasons')) as { id: number; name: string }[]
  }),
})
