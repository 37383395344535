import { types, flow } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const ExperienceModel = types.model('Profession', {
  id: types.identifierNumber,
  work_category_id: types.number,
  work_category_name: types.string,
  image_url: types.string,
  company_name: types.string,
  location_name: types.string,
  start_year: types.number,
  end_year: types.number,
  description: types.maybeNull(types.string),
  years_experience: types.number,
})

export const experienceActions = (self: DataStore) => ({
  getExperienceList: flow(function*() {
    self.experience = yield self.request('get', `sp/profile/experience/company/list`)
  }),

  createExperience: flow(function*(experienceForm: ExperienceForm) {
    yield self.request('post', 'sp/profile/experience/company/create', experienceForm)
  }),

  updateExperience: flow(function*(experienceForm: ExperienceForm & { id: number }) {
    yield self.request('post', `sp/profile/experience/company/update`, experienceForm)
  }),

  deleteExperience: flow(function*(id: number) {
    yield self.request('post', 'sp/profile/experience/company/delete', { id })
  }),
})

export const experienceViews = (self: DataStore) => ({
  getExperienceById(id: number) {
    return self.experience.find(exp => exp.id === id)
  },
})

export type Experience = typeof ExperienceModel.Type
export type ExperienceSnapshot = typeof ExperienceModel.SnapshotType

export type ExperienceForm = {
  work_category_id: number
  company_name: string
  location_name: string
  start_year: number
  end_year: number
  description?: string
}
