import * as React from 'react'
import { acceptJobEvents, closeWebView } from '../../utils/webview'
import './job-not-found.scss'

const JobNotFound = () => {
  return (
    <div className="JobNotFound">
      <p className="JobNotFound__text">This Job Does Not Exist, please explore more jobs</p>
      <button
        onClick={() => closeWebView(acceptJobEvents.job_application_failed)}
        className="JobNotFound__exploreButton"
      >
        Explore jobs
      </button>
    </div>
  )
}

export default JobNotFound
