import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import './payout-paymax-location.scss'
import PayoutFooter from '../payout-footer/payout-footer'
import { Formik, Form, FormikProps } from 'formik'
import * as Yup from 'yup'
import SelectInput from '../select-input/select-input'
import { useQuery, useStore } from '../../models/utils/mst-hooks'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import { observer } from 'mobx-react'
import { useState } from 'react'
import { LariExchangeLocationsType } from '../../../../common/models/lari-exchange-location'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import { useEffect } from 'react'

export interface PaymaxLocation {
  selected_region: string
  selectedLariExchangeLocation: string
}

interface PayoutPaymaxLocationInterface {
  onComplete: () => void
}

let formikRef: FormikProps<PaymaxLocation>

const PayoutPaymaxLocation = observer(({ onComplete }: PayoutPaymaxLocationInterface) => {
  const {
    payout,
    setPayoutData,
    getLariExchangeLocations,
    getRegions,
    regions,
    exchangeLocationsList,
  } = useStore().data

  const lariExchangeLocations: LariExchangeLocationsType[] = JSON.parse(JSON.stringify(exchangeLocationsList))
  const [exchangeLocations, setExchangeLocations] = useState<LariExchangeLocationsType[]>([])
  const exchangeLocation = useQuery(getLariExchangeLocations, {
    filterError: [ApiErrorTypes.ClientError],
  })
  const regionsQuery = useQuery(getRegions, {
    filterError: [ApiErrorTypes.ClientError],
  })

  useEffect(() => {
    setExchangeLocations(() =>
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      lariExchangeLocations.filter(
        (branch: LariExchangeLocationsType): boolean =>
          branch.emirate.toLowerCase().replace(/-/g, ' ') === payout?.selected_region.toLowerCase().replace(/-/g, ' '),
      ),
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [payout])

  const filterLocationsByRegion = (regionName: string | undefined) => {
    setExchangeLocations(() => {
      const filteredExchanges: LariExchangeLocationsType[] = lariExchangeLocations.filter(
        branch => branch.emirate.toLowerCase().replace(/-/g, ' ') === regionName?.toLowerCase().replace(/-/g, ' '),
      )
      if (filteredExchanges.length === 1) {
        formikRef.setFieldValue('selectedLariExchangeLocation', filteredExchanges[0].name)
      } else {
        formikRef.setFieldValue('selectedLariExchangeLocation', '')
      }

      return filteredExchanges
    })
  }

  const findExchangeId = (name: string | undefined): string => {
    const foundExchange = exchangeLocations.find(branch => branch.name.toLowerCase() === name?.toLowerCase())
    return foundExchange ? String(foundExchange.id) : ''
  }
  return (
    <div className="PayoutSelectBank">
      <Header title="Payout" />
      <h2 className="PayoutSelectBank__title">From which Lari Exchange do you want to pick up your card?</h2>

      {!exchangeLocation.isLoading && !regionsQuery.isLoading ? (
        <Formik
          innerRef={(ref: FormikProps<PaymaxLocation>) => (formikRef = ref)}
          initialValues={{
            selected_region: payout?.selected_region ?? '',
            selectedLariExchangeLocation: payout?.selectedLariExchangeLocation ?? '',
          }}
          onSubmit={values => {
            setPayoutData({
              ...values,
              branch_id: Number(findExchangeId(values.selectedLariExchangeLocation)),
            })
            onComplete()
          }}
          validationSchema={Yup.object().shape({
            selected_region: Yup.string().required('This field is required'),
            selectedLariExchangeLocation: Yup.string().required('This field is required'),
          })}
        >
          {(formikProps: FormikProps<PaymaxLocation>) => (
            <Form className="PayoutSelectBank__formBody" noValidate>
              <SelectInput<PaymaxLocation>
                options={regions}
                getValue={filterLocationsByRegion}
                label="Select Emirate"
                formikKey="selected_region"
                formikProps={formikProps}
              />
              <SelectInput
                disabled={!Boolean(formikProps.values['selected_region'])}
                options={exchangeLocations}
                label="Location"
                formikKey="selectedLariExchangeLocation"
                formikProps={formikProps}
              />
              <div className="footer-container">
                <PayoutFooter stepsCount={6} activeStepIndex={4} />
              </div>
            </Form>
          )}
        </Formik>
      ) : (
        <LoadingSpinner />
      )}
    </div>
  )
})

export default PayoutPaymaxLocation
