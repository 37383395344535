import * as React from 'react'

import checkmarkImg from './checkmark.svg'
import './policy-item.scss'

type PolicyItemProps = {
  selected: boolean
  label: string
  onChange: () => void
}

export const PolicyItem = ({ selected, label, onChange }: PolicyItemProps) => {
  return (
    <div className="PolicyItem" onClick={onChange}>
      <div className={`PolicyItem__checkbox ${selected ? 'active' : ''}`}>
        {selected && <img src={checkmarkImg} className="PolicyItem__checkbox__img" />}
      </div>
      <span className="PolicyItem__label">{label}</span>
    </div>
  )
}
