import { types } from 'mobx-state-tree'
import { DataStore } from './data-store/data-store'

export const JobRequirementsAnswerModel = types.model('JobRequirementsAnswerModel', {
  id: types.string,
  answer: types.string,
  requirement: types.string,
})

export const jobRequirementsActions = (self: DataStore) => ({
  saveRequirementAnswer: function(id: string, answer: string, requirement: string) {
    self.jobRequirementsAnswers.set(id, { id, answer, requirement })
  },
})

export const jobRequirementsViews = (self: DataStore) => ({
  getAnswers() {
    return Array.from(self.jobRequirementsAnswers.values())
  },
})

export type JobRequirementsAnswerType = typeof JobRequirementsAnswerModel.Type
export type JobRequirementsAnswerSnapshot = typeof JobRequirementsAnswerModel.SnapshotType
