import { onSnapshot } from 'mobx-state-tree'
import { Environment } from '../enviroment'
import { RootStoreModel, RootStoreSnapshot } from './root-store'
import * as storage from '../../utils/storage'

/**
 * The key we'll be saving our state as within async storage.
 */
const ROOT_STATE_STORAGE_KEY = 'root-storage'

/**
 * Setup the environment that all the models will be sharing.
 *
 * The environment includes other functions that will be picked from some
 * of the models that get created later. This is how we loosly couple things
 * like events between models.
 */
export function createEnvironment() {
  const env = new Environment()
  return env
}

/**
 * Setup the root state.
 */
export function setupRootStore() {
  // prepare the environment that will be associated with the RootStore.
  const env = createEnvironment()

  const data = (storage.load(ROOT_STATE_STORAGE_KEY) || { data: {} }) as RootStoreSnapshot
  const rootStore = RootStoreModel.create(data, env)

  // track changes & save to storage
  // eslint-disable-next-line @typescript-eslint/no-misused-promises
  onSnapshot(rootStore, snapshot => storage.save(ROOT_STATE_STORAGE_KEY, snapshot))

  return rootStore
}
