import * as React from 'react'
import * as ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { ApiError } from 'ogram-react/api/api-types'
import { AppLayout } from './components/app-layout/app-layout'
import { ErrorBoundary } from './components/error/error'
import './index.scss'
import { setupRootStore } from './models/root-store/setup-root-store'
import { MSTProvider } from './models/utils/mst-hooks'
// import Modal from 'react-modal'

// if (Modal.defaultStyles.overlay) {
//   Modal.defaultStyles.overlay.backgroundColor = 'rgba(0,0,0, 0.2)'
// }

// @ts-ignore
window.setImmediate = setTimeout

export function App() {
  const store = setupRootStore()

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return (
    <MSTProvider value={store}>
      <Router>
        <React.Suspense fallback="LoadingSpinner">
          <MSTProvider value={store}>
            <ErrorBoundary>
              <AppLayout />
            </ErrorBoundary>
          </MSTProvider>
        </React.Suspense>
      </Router>
    </MSTProvider>
  )
}

ReactDOM.render(<App />, window.document.getElementById('root'))
// Modal.setAppElement('#root')

// TODO: un-comment next line to allow hot reload but ts complains, need investigation
// if (module.hot) {
//   // enables hot module replacement if plugin is installed
//   module.hot.accept()
// }

// to prevent noisy uncaught errors
window.addEventListener('error', event => {
  if (event.error instanceof ApiError) {
    event.preventDefault()
  }
})
