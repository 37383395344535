/**
 * Loads something from storage and runs it thru JSON.parse.
 *
 * @param key The key to fetch.
 */
export function load(key: string): unknown | null {
  try {
    const almostThere = sessionStorage.getItem(key)
    return JSON.parse(almostThere ?? '') as unknown
  } catch {
    return null
  }
}

/**
 * Saves an object to storage.
 *
 * @param key The key to fetch.
 * @param value The value to store.
 */
export function save(key: string, value: unknown): boolean {
  try {
    sessionStorage.setItem(key, JSON.stringify(value))
    return true
  } catch {
    return false
  }
}

/**
 * Removes something from storage.
 *
 * @param key The key to kill.
 */
export function remove(key: string): void {
  try {
    sessionStorage.removeItem(key)
  } catch (err) {
    console.error(err)
  }
}

/**
 * Burn it all to the ground.
 */
export function clear(): void {
  try {
    sessionStorage.clear()
  } catch (err) {
    console.error(err)
  }
}
