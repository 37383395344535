import * as React from 'react'
import PayoutFooter from '../payout-footer/payout-footer'
import './payout-paymax-timing.scss'
import Header from '../../pages/accept-job/components/header/header'

interface PayoutPaymaxTimingProps {
  onComplete: () => void
}

const PayoutPaymaxTiming = ({ onComplete }: PayoutPaymaxTimingProps) => {
  return (
    <div className="PayoutPaymaxTerms">
      <Header title="Payout" />
      <h2 className="PayoutPaymaxTerms__title">When will I get it?</h2>
      <div className="PayoutPaymaxTerms__body">
        <p className="PayoutPaymaxTerms__subText">
          Once you have completed your first shift with Ogram, we will request a new Pay Max card for you.
          <br />
          <br />
          This will take 3-5 working days and you will get notified to pick up the physical card from a Lari Exchange
          branch of your choice.
        </p>
      </div>
      <div className="PayoutPaymaxTerms__footerContainer">
        <PayoutFooter stepsCount={6} handleSubmit={onComplete} activeStepIndex={3} />
      </div>
    </div>
  )
}

export default PayoutPaymaxTiming
