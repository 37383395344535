import * as React from 'react'
import { useLocation } from 'react-router-dom'
import QuizQuestion from '../quiz-question/quiz-question'
import QuizOverview from '../quiz-overview/quiz-overview'
import { QuizDataType } from '../../../../common/models/quiz'

const queryString = require('query-string')

interface QuizProps {
  onComplete: () => void
  quiz: QuizDataType
}

const Quiz = ({ onComplete, quiz }: QuizProps) => {
  const location = useLocation()
  const questionIndex = queryString.parse(location.search).questionIndex

  const renderQuiz = () => {
    return questionIndex ? (
      <QuizQuestion onComplete={onComplete} question={quiz.questions[questionIndex]} />
    ) : (
      <QuizOverview />
    )
  }

  return renderQuiz()
}

export default Quiz
