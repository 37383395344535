import * as React from 'react'
import './overview-steps.scss'
import doneIcon from '../../assets/check.svg'
import pendingIcon from '../../assets/pending.svg'
import activeIcon from '../../assets/active.svg'
import clock from '../../assets/clock.svg'
import { useStore } from '../../../../models/utils/mst-hooks'
import { observer } from 'mobx-react'
import { StepType } from '../../../../../../common/models/accept-job-progress-steps'
import { useEffect } from 'react'
import { findStepByPropKey } from '../../../../../../common/utils/common'
import { DocumentStatus } from '../../../../../../common/models/documents'

export enum Steps {
  documents = 'is_missing_documents',
  quiz = 'is_training_complete',
  payout = 'is_payout_method_complete',
  requirements = 'is_policies_complete',
  shifts = 'is_shifts_complete',
}

const OverviewStepsContent: StepType[] = [
  {
    description: 'Upload required documents',
    time: 3,
    status: 'active',
    propKey: 'is_missing_documents',
  },
  {
    description: 'Complete overview training',
    time: 1,
    status: 'pending',
    propKey: 'is_training_complete',
  },
  {
    description: 'Complete payout method',
    time: 3,
    status: 'pending',
    propKey: 'is_payout_method_complete',
  },
  {
    description: 'Requirements & Policies',
    time: 2,
    status: 'pending',
    propKey: 'is_policies_complete',
  },
  {
    description: 'Review shifts summary',
    time: 1,
    status: 'pending',
    propKey: 'is_shifts_complete',
  },
]

export enum OverviewStatus {
  active = 'active',
  done = 'done',
  pending = 'pending',
  pendingApproval = 'pendingApproval',
  rejected = 'rejected',
}

const OverviewSteps = observer(() => {
  const { data } = useStore()
  const { profile, setSteps, getSteps, job } = data
  const steps = JSON.parse(JSON.stringify(getSteps()))

  useEffect(() => {
    let clonedSteps = JSON.parse(JSON.stringify(OverviewStepsContent))

    if (profile.basic) {
      if (!steps.length) {
        const documentsStep = findStepByPropKey(clonedSteps, Steps.documents) as StepType
        const requirementsStep = findStepByPropKey(clonedSteps, Steps.requirements) as StepType
        const quizStep = findStepByPropKey(clonedSteps, Steps.quiz) as StepType
        const payoutStep = findStepByPropKey(clonedSteps, Steps.payout) as StepType
        if (profile.basic.is_training_complete) {
          clonedSteps = clonedSteps.filter((step: StepType) => step.propKey !== Steps.quiz)
          payoutStep.status = OverviewStatus.active
        }
        if (profile.basic.is_payout_method_complete) {
          clonedSteps = clonedSteps.filter((step: StepType) => step.propKey !== Steps.payout)
          if (job?.missing_documents.length) {
            documentsStep.status = OverviewStatus.active
          } else if (!profile.basic.is_training_complete) {
            quizStep.status = OverviewStatus.active
          } else if (job?.work_requirements.length) {
            requirementsStep.status = OverviewStatus.active
          }
        }
        if (profile.basic.is_policies_complete && !job?.work_requirements.length) {
          clonedSteps = clonedSteps.filter((step: StepType) => step.propKey !== Steps.requirements)
        }
        if (!job?.missing_documents.length) {
          clonedSteps = clonedSteps.filter((step: StepType) => step.propKey !== Steps.documents)
          quizStep.status = OverviewStatus.active
        } else {
          const isAllDocumentsPendingApproval = job.missing_documents.every(
            document => document.status === DocumentStatus.inReview,
          )
          const isAnyDocumentRejected = job.missing_documents.some(
            document => document.status === DocumentStatus.rejected,
          )
          if (isAllDocumentsPendingApproval) {
            documentsStep.status = OverviewStatus.pendingApproval
          } else if (isAnyDocumentRejected) {
            documentsStep.status = OverviewStatus.rejected
          } else {
            documentsStep.status = OverviewStatus.active
          }
        }
        setSteps(JSON.parse(JSON.stringify(clonedSteps)))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  useEffect(() => {
    const clonedSteps = JSON.parse(JSON.stringify(steps))
    const documentsStep = findStepByPropKey(clonedSteps, Steps.documents)
    if (documentsStep) {
      if (documentsStep.status === OverviewStatus.pendingApproval && !job?.missing_documents.length) {
        documentsStep.status = OverviewStatus.done
        setSteps(JSON.parse(JSON.stringify(clonedSteps)))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderStatusIcon = (status: string): string => {
    switch (status) {
      case OverviewStatus.active:
        return activeIcon as string
      case OverviewStatus.pendingApproval:
        return activeIcon as string
      case OverviewStatus.rejected:
        return activeIcon as string
      case OverviewStatus.done:
        return doneIcon as string
      default:
        return pendingIcon as string
    }
  }

  return (
    <div className="OverviewSteps">
      <div className="OverviewSteps__steps-connecting-line"></div>
      {steps.map((step: StepType, i: number) => (
        <div key={i} className="OverviewSteps__step">
          <img className="OverviewSteps__step-status" src={renderStatusIcon(step.status)} />
          <div className="OverviewSteps__step-content">
            <h4
              className={
                step.status === OverviewStatus.pending
                  ? 'OverviewSteps__step-description OverviewSteps__step-description--greyed'
                  : 'OverviewSteps__step-description'
              }
            >
              {step.description}
            </h4>
            {step.status === OverviewStatus.pendingApproval ? (
              <div className="OverviewSteps__pendingApproval">
                <span>Pending Approval</span>
              </div>
            ) : step.status === OverviewStatus.rejected ? (
              <div className="OverviewSteps__rejected">
                <span>Rejected</span>
              </div>
            ) : step.status !== OverviewStatus.done ? (
              <div className="OverviewSteps__stepTime">
                <img className="OverviewSteps__clockIcon" src={clock} />
                <span>{step.time} Min</span>
              </div>
            ) : null}
          </div>
        </div>
      ))}
    </div>
  )
})

export default OverviewSteps
