import * as React from 'react'
import './modal.scss'

export const Modal = ({
  children,
  className,
  modalIsOpen,
}: {
  children: React.ReactNode
  className: string
  modalIsOpen: boolean
}) => {
  return (
    <>
      {modalIsOpen && (
        <div className="modal">
          <div className={`${className} modal__body`}>{children}</div>
          <div className="modal__background"></div>
        </div>
      )}
    </>
  )
}
