import * as React from 'react'
import { useParams, generatePath, useHistory } from 'react-router-dom'
import PayoutPaymaxTerms from '../../../components/payout-paymax-terms/payout-paymax-terms'
import { routes } from '../../../routes'

const AcceptJobPayoutPaymaxTerms = () => {
  const { id } = useParams<{ id: string }>()
  const history = useHistory()

  const onComplete = () => history.push(generatePath(routes.acceptJobPayoutPayMaxTiming, { id }))

  return <PayoutPaymaxTerms onComplete={onComplete} />
}

export default AcceptJobPayoutPaymaxTerms
