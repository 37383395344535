import * as React from 'react'
import Header from '../../pages/accept-job/components/header/header'
import './payout-select-bank.scss'
import PayoutFooter from '../payout-footer/payout-footer'
import TextInput from '../text-input/text-input'
import { Formik, Form, FormikProps, Field } from 'formik'
import * as Yup from 'yup'
import { useQuery, useStore } from '../../models/utils/mst-hooks'
import { observer } from 'mobx-react'
import { ApiErrorTypes } from 'ogram-react/api/api-types'
import LoadingSpinner from '../loading-spinner/loading-spinner'
import Select from 'react-select'
import { BankType } from '../../../../common/models/banks'

export interface BankDataForm {
  selected_bank: string
  swift?: string
}

interface PayoutSelectBankInterface {
  onComplete: () => void
}

const PayoutSelectBank = observer(({ onComplete }: PayoutSelectBankInterface) => {
  const { setPayoutData, getBanks, getBanksList, payout } = useStore().data
  const banksQuery = useQuery(getBanks, {
    filterError: [ApiErrorTypes.ClientError],
  })
  const banks = getBanksList()

  const extractBankById = (bankId: string): BankType | undefined => {
    return banks.find(bank => String(bank.id) === bankId)
  }

  return (
    <>
      {banksQuery.isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="PayoutSelectBank">
          <Header title="Payout" />
          <h2 className="PayoutSelectBank__title">Select bank in the UAE</h2>
          <Formik
            initialValues={{
              selected_bank: payout?.selected_bank ?? '',
              swift: payout?.swift ?? '',
            }}
            onSubmit={values => {
              setPayoutData({ selected_bank: String(values.selected_bank), swift: values.swift })
              onComplete()
            }}
            validationSchema={Yup.object().shape({
              selected_bank: Yup.string().required('This field is required'),
            })}
          >
            {(formikProps: FormikProps<BankDataForm>) => (
              <Form className="PayoutSelectBank__formBody" noValidate>
                <Field name="selected_bank">
                  {() => (
                    <>
                      <Select
                        className="PayoutSelectBank__select"
                        classNamePrefix="select"
                        isSearchable={true}
                        name="selected_bank"
                        placeholder="Select Bank"
                        defaultValue={extractBankById(formikProps.values.selected_bank)}
                        hideSelectedOptions={true}
                        getOptionLabel={(option: BankType) => option.name}
                        getOptionValue={(option: BankType) => String(option.id)}
                        onChange={(selectedBank: BankType | null) => {
                          formikProps.setFieldValue('selected_bank', selectedBank?.id)
                          formikProps.setFieldValue('swift', selectedBank?.code)
                        }}
                        options={banks as BankType[]}
                      />
                      <p className="PayoutSelectBank__errorMessage">
                        {formikProps.touched['selected_bank'] && formikProps.errors['selected_bank']}
                      </p>
                    </>
                  )}
                </Field>
                <TextInput disabled label="SWIFT Code" formikKey="swift" formikProps={formikProps} />
                <div className="footer-container">
                  <PayoutFooter activeStepIndex={1} />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      )}
    </>
  )
})

export default PayoutSelectBank
