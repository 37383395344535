import { types, SnapshotOut, Instance } from 'mobx-state-tree'
import { withRequest } from 'ogram-react/mst/extenstions/request'
import { ProfessionModel, professionActions, professionViews } from '../profession'
import { ProfileStoreModel, profileActions, profileViews } from '../profile'
import { authActions } from '../auth'
import { quizActions, QuizModel, quizViews } from '../quiz'
import { jobActions, JobDetailsModel, jobViews } from '../job'
import { progressStepsActions, progressStepsViews, step } from '../accept-job-progress-steps'
import { payoutActions, PayoutModel } from '../payout'
import { UserPayoutModel, userPayoutActions, userPayoutViews } from '../user-payout'
import { regionActions, RegionModel, regionViews } from '../regions'
import { bankActions, BankModel, bankViews } from '../banks'
import { NationalityModel, nationalityActions, nationalityViews } from '../nationality'
import { LanguageModel, languageActions } from '../languages'
import { OpportunityStoreModel, opportunityActions, opportunityViews } from '../opportunity'
import { NotificationStoreModel, notificationActions, notificationViews } from '../notification'
import { jobRequirementsActions, JobRequirementsAnswerModel, jobRequirementsViews } from '../job-requirements'
import { ExperienceModel, experienceActions, experienceViews } from '../experience'
import { DocumentFieldModel, documentActions, documentViews } from '../documents'
import { commonActions } from '../common'
import { BookedShiftsStoreModel, bookedShiftsActions, bookedShiftsViews } from '../booked-shifts'
import { AppliedJobsModel, appliedJobsActions, apliedJobsViews } from '../applied-jobs'
import { InvitedJobsModel, invitedJobsActions, invitedJobsViews } from '../invited-jobs'
import { PastJobsModel, pastJobsActions, pastJobsViews } from '../past-jobs'
import { LariExchangeLocationModel, lariLocationsActions, lariLocationsViews } from '../lari-exchange-location'
import { UniformModel, uniformActions, uniformViews } from '../uniforms'
import { EducationModel, educationActions, educationViews } from '../education'

export const BaseDataStoreModel = types
  .model('DataStore', {
    logged: types.optional(types.boolean, false),
    profile: types.optional(ProfileStoreModel, {}),
    quiz: types.optional(QuizModel, {}),
    job: types.maybeNull(JobDetailsModel),
    acceptJobProgressSteps: types.optional(types.array(step), []),
    opportunity: types.optional(OpportunityStoreModel, {}),
    payout: types.maybeNull(PayoutModel),
    userPayout: types.maybeNull(UserPayoutModel),
    regions: types.optional(types.array(RegionModel), []),
    banks: types.map(BankModel),
    professions: types.map(ProfessionModel),
    nationalities: types.map(NationalityModel),
    languages: types.optional(types.array(LanguageModel), []),
    notification: types.optional(NotificationStoreModel, {}),
    jobRequirementsAnswers: types.optional(types.map(JobRequirementsAnswerModel), {}),
    experience: types.optional(types.array(ExperienceModel), []),
    education: types.map(EducationModel),
    documents: types.map(DocumentFieldModel),
    bookedShifts: types.maybeNull(BookedShiftsStoreModel),
    appliedJobs: types.optional(AppliedJobsModel, {}),
    invitedJobs: types.optional(InvitedJobsModel, {}),
    pastJobs: types.optional(PastJobsModel, {}),
    lariExchangeBranches: types.optional(types.array(LariExchangeLocationModel), []),
    uniforms: types.map(UniformModel),
  })
  .extend(withRequest)

export const DataStoreModel = BaseDataStoreModel.actions(profileActions)
  .actions(authActions)
  .actions(professionActions)
  .actions(quizActions)
  .actions(jobActions)
  .actions(progressStepsActions)
  .actions(opportunityActions)
  .actions(payoutActions)
  .actions(regionActions)
  .actions(bankActions)
  .actions(nationalityActions)
  .actions(languageActions)
  .actions(notificationActions)
  .actions(jobRequirementsActions)
  .actions(experienceActions)
  .actions(documentActions)
  .actions(commonActions)
  .actions(bookedShiftsActions)
  .actions(appliedJobsActions)
  .actions(invitedJobsActions)
  .actions(pastJobsActions)
  .actions(lariLocationsActions)
  .actions(uniformActions)
  .actions(educationActions)
  .actions(userPayoutActions)
  .views(professionViews)
  .views(jobViews)
  .views(quizViews)
  .views(progressStepsViews)
  .views(opportunityViews)
  .views(regionViews)
  .views(bankViews)
  .views(nationalityViews)
  .views(profileViews)
  .views(notificationViews)
  .views(jobRequirementsViews)
  .views(documentViews)
  .views(bookedShiftsViews)
  .views(apliedJobsViews)
  .views(invitedJobsViews)
  .views(pastJobsViews)
  .views(lariLocationsViews)
  .views(uniformViews)
  .views(experienceViews)
  .views(educationViews)
  .views(userPayoutViews)

export type DataStore = Instance<typeof BaseDataStoreModel>
export type DataStoreSnapshot = SnapshotOut<typeof BaseDataStoreModel>
