import * as React from 'react'
import Stepper from '../stepper/stepper'
import './payout-footer.scss'

interface PayoutFooterProps {
  handleSubmit?: (params?: unknown) => void
  activeStepIndex: number
  stepsCount?: number
}

const PayoutFooter = ({ handleSubmit, activeStepIndex, stepsCount }: PayoutFooterProps) => {
  return (
    <div className="Payout__footer">
      <span className="Payout__hint">*We never share your information with anyone</span>
      <div className="Payout__stepperContainer">
        <Stepper stepsCount={stepsCount ?? 5} activeStepIndex={activeStepIndex} />
      </div>
      <button type="submit" className="Payout__nextButton" onClick={handleSubmit}>
        Next
      </button>
    </div>
  )
}

export default PayoutFooter
