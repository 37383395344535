import * as React from 'react'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'

import { useStore } from '../../../models/utils/mst-hooks'
import { routes } from '../../../routes'
import { useSameStepsComplete } from '../../../utils/use-same-steps-complete'
import Quiz from '../../../components/quiz/quiz'

const AcceptJobQuiz = observer(() => {
  const history = useHistory()

  const { data } = useStore()
  const { quiz } = data

  const { nextRouteObject, checkStepAsDone } = useSameStepsComplete(
    {
      pathname: routes.acceptJobProgress,
      search: `?nextRoute=${
        data.profile.basic?.is_payout_method_complete
          ? routes.acceptJobRequirementsAndPolicies
          : routes.acceptJobPayoutChoosePayment
      }&dismissRefreshProfileData=1`,
    },
    'is_training_complete',
    data.profile.basic?.is_payout_method_complete ? 'is_policies_complete' : 'is_payout_method_complete',
  )

  const onComplete = () => {
    checkStepAsDone()
    history.push(nextRouteObject())
  }

  return <Quiz onComplete={onComplete} quiz={quiz} />
})

export default AcceptJobQuiz
